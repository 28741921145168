<template>
  <div class="micro-product-card-wrapper">
    <img :src="product.thumbnail" alt="thumbnail" class="product-image">
    <div class="detail-wrapper">
      <p class="product-name">{{ product.name }}</p>
      <p class="product-price">฿{{ product.price }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="scss" scoped>

.micro-product-card-wrapper {
  display: flex;
  margin-bottom: 24px;
  cursor: pointer;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.product-image {
  width: 32px;
  height: 32px;
  border-radius: 6px;
}

.detail-wrapper {
  margin-left: 12px;
  font-size: 14px;
}

.product-price {
  color: #FD6868;
  font-weight: bold;
}
</style>
