import api from '@/api'
import parseObjectToParamQuery from '@/utils/parseObjectToParamQuery'

const getProductById = async (id) => {
  const result = await api.get(`/api/store/product/${id}`)
  
  return result
}

const getFavoriteProducts = async () => {
  const result = await api.get('/api/store/product/my_favorite')
  
  return result
}

const getProductsByCategoryId = async (id) => {
  const result = await api.get(`/api/store/category/${id}`)
  
  return result
}

const getBanners = async () => {
  const result = await api.get('/api/store/banner', {
    noRequiredToken: true
  })
  
  return result
}

const updateFavoriteProductById = async (id, is_favorite) => {
  const result = await api.post(`/api/store/product/${id}/favorite`, { is_favorite }, {
    loading: false,
  })
  
  return result
}

const getProductsByKeywords = async (keywords) => {
  const query = parseObjectToParamQuery(keywords)
  const result = await api.get(`/api/store/search${query}`, { loading: false })
    
  return result
}

const getMyMedicine = async (type) => {
  const result = await api.get(`/api/store/product/my_medicine?type=${type}`)
    
  return result
}

const updateProductStock = async (body) => {
  const result = await api.post('/api/store/product_stock', body)
  
  return result
}

export default {
  getBanners,
  getMyMedicine,
  getProductById,
  updateProductStock,
  getFavoriteProducts,
  getProductsByKeywords,
  getProductsByCategoryId,
  updateFavoriteProductById,
}
