import cartApi from '@/api/cart'
import gbPrimePayApi from '@/api/gbPrimePay'
import router from '@/router'
import i18n from '@/i18n'

const errs = ['ไม่มีสินค้านี้อยู่ในระบบ']

export default {
  namespaced: true,
  state: {
    data: null
  },
  getters: {
    totalItemAmount: state => state.data?.cart_calculated?.sum_quantity || 0,
  },
  mutations: {
    RESET_DATA(state) {
      state.data = null
    },
    SET_DATA(state, data) {
      state.data = {...state.data, ...data}
    },
    SET_GB_PAY_TOKEN(state, data) {
      state.data.gbPayToken = data
    },
    SET_COUPON(state, data) {
      state.data = {...state.data, cart_calculated: data}
    },
    SET_SELECTED_PAYMENT(state, data) {
      state.data.selectedPayment = data
    },
    SET_COUPON_CODE(state, data) {
      state.data.coupon_code = data
      if (!data) {
        state.data.cart_calculated.discount_info = ''
      }
    },
  },
  actions: {
    async fetchGetCart({commit}) {
      try {
        const response = await cartApi.getCart()
        commit('SET_DATA', response)
      }
      catch (err) {
        console.log(err)
        console.log(JSON.parse(localStorage.getItem('itemData')))
        commit('SET_DATA', JSON.parse(localStorage.getItem('itemData')))
      }
    },
    async fetchAddProduct({commit}, data) {
      try {
        const response = await cartApi.addProduct(data)
        commit('SET_DATA', response)
        commit('snackBar/SET_DATA', {message: i18n.global.t('myCart.successAdd'), color: 'success'}, {root: true})
      }
      catch (err) {
        
        if (errs.includes(err.detail)) {
          commit('snackBar/SET_DATA', {message: i18n.global.t('notFoundProduct'), color: 'warning'}, {root: true})
        }
        else {
        let currentCart = JSON.parse(localStorage.getItem('cartItem'))
        let cartItem = {
          "cart_item_id": 0,
          "amount": 1,
          "price": data.price,
          "status": "active",
          "product": {
            "id": data.product_id,
            "name": data.name.th,
            "price": data.price,
            "thumbnail": data.thumbnail,
            "description": data.description,
          }
        }
        if (currentCart && Array.isArray(currentCart) && currentCart.length > 0) {
          

          let hasData = false
          for (let i = 0; i < currentCart.length; i++) {
            if (currentCart[i].product.id == data.product_id) {
              currentCart[i].amount++
              hasData = true
            }
          }

          if (!hasData) {
            cartItem.cart_item_id = currentCart.length
            currentCart.push(cartItem)
          }

        } else {

          currentCart = [cartItem]
        }
        let total = 0
        currentCart.forEach(element => {
          total += element.price * element.amount
        })
          let itemData = {
            "cart_items": currentCart,
            "cart_calculated": {
              "coin_by_payment_channel": {
                "scb_qr_code": 0,
                "scb_easy_app": 0,
                "credit_card": 0
              },
              "num_product_item": 1,
              "sum_quantity": 1,
              "total": total,
              "grand_total": parseFloat(total) + 40,
              "shipping_fee": 40,
              "discount": 0,
              "discount_info": "",
              "discount_percent": 0,
              "count_cart_item": 1,
              "vat": 17,
              "vat_percent": 7,
              "error_message": ""
            },
            "cart_suggests": [],
            "cart_promotions": [],
            "coupon_promotions": [],
            "coupon_code": "",
            "payment_channels": [
              {
                "title": "QR Code",
                "value": "scb_qr_code"
              },
              {
                "title": "SCB Easy App (เฉพาะการทำรายการในโทรศัพท์)",
                "value": "scb_easy_app"
              },
              {
                "title": "บัตรเครดิต / บัตรเดบิต (การจ่ายเงินด้วยบัตรเครดิตจะไม่ได้รับ coins)",
                "value": "credit_card"
              }
            ],
            "GBPAY_PUBLIC_KEY": "GXxdXHafevc1wGglODm3zsE3qcAbaa0f",
            "GBPAY_API_BASE_URL": "https://api.globalprimepay.com",
            "coin": 0,
            "is_branch_staff": false
          }
          localStorage.setItem('itemData', JSON.stringify(itemData))
          localStorage.setItem('cartItem', JSON.stringify(currentCart))
          commit('SET_DATA', itemData)
          commit('snackBar/SET_DATA', {message: i18n.global.t('myCart.successAdd'), color: 'success'}, {root: true})
          // sessionStorage.setItem('redirectTo', window.location.href)
          // commit('snackBar/SET_DATA', { message: i18n.global.t('loginFirst'), color: 'warning' }, { root: true })
          // router.push('/user/login')
        }
      }
    },
    async fetchAddProductAndCheckout({commit}, data) {
      try {
        const response = await cartApi.addProduct(data)
        commit('SET_DATA', response)
        router.push('/my-cart/checkout')
      }
      catch (err) {
        let currentCart = JSON.parse(localStorage.getItem('cartItem'))
        let cartItem = {
          "cart_item_id": 0,
          "amount": 1,
          "price": data.price,
          "status": "active",
          "product": {
            "id": data.product_id,
            "name": data.name.th,
            "price": data.price,
            "thumbnail": data.thumbnail,
            "description": data.description,
          }
        }
        if (currentCart && Array.isArray(currentCart) && currentCart.length > 0) {
          

          let hasData = false
          for (let i = 0; i < currentCart.length; i++) {
            if (currentCart[i].product.id == data.product_id) {
              currentCart[i].amount++
              hasData = true
            }
          }

          if (!hasData) {
            cartItem.cart_item_id = currentCart.length
            currentCart.push(cartItem)
          }

        } else {

          currentCart = [cartItem]
        }
        let total = 0
        currentCart.forEach(element => {
          total += element.price * element.amount
        })
        let itemData = {
          "cart_items": currentCart,
          "cart_calculated": {
            "coin_by_payment_channel": {
              "scb_qr_code": 0,
              "scb_easy_app": 0,
              "credit_card": 0
            },
            "num_product_item": 1,
            "sum_quantity": 1,
            "total": total,
            "grand_total": parseFloat(total) + 40,
            "shipping_fee": 40,
            "discount": 0,
            "discount_info": "",
            "discount_percent": 0,
            "count_cart_item": 1,
            "vat": 17,
            "vat_percent": 7,
            "error_message": ""
          },
          "cart_suggests": [],
          "cart_promotions": [],
          "coupon_promotions": [],
          "coupon_code": "",
          "payment_channels": [
            {
              "title": "QR Code",
              "value": "scb_qr_code"
            },
            {
              "title": "SCB Easy App (เฉพาะการทำรายการในโทรศัพท์)",
              "value": "scb_easy_app"
            },
            {
              "title": "บัตรเครดิต / บัตรเดบิต (การจ่ายเงินด้วยบัตรเครดิตจะไม่ได้รับ coins)",
              "value": "credit_card"
            }
          ],
          "GBPAY_PUBLIC_KEY": "GXxdXHafevc1wGglODm3zsE3qcAbaa0f",
          "GBPAY_API_BASE_URL": "https://api.globalprimepay.com",
          "coin": 0,
          "is_branch_staff": false
        }
        localStorage.setItem('itemData', JSON.stringify(itemData))
        localStorage.setItem('cartItem', JSON.stringify(currentCart))
        commit('SET_DATA', itemData)
        router.push('/my-cart/checkout')
        // sessionStorage.setItem('redirectTo', window.location.href)
        // router.push('/user/login')
        // commit('snackBar/SET_DATA', { message: i18n.global.t('loginFirst'), color: 'warning' }, { root: true })
      }
    },
    async fetchIncreaseProduct({commit}, data) {
        if(data.user!= undefined){
          const response = await cartApi.addProduct({
            product_id: data.product_id,
            quantity: 1,
            add_from: 'user'
          })
          commit('SET_DATA', response)
        }else{
          let currentCart = JSON.parse(localStorage.getItem('cartItem'))
          let total = 0
          currentCart.forEach(element => {
            if(element.product.name == data.product_name){
              element.amount++
            }
            total += element.price * element.amount
          })
          localStorage.setItem('cartItem', JSON.stringify(currentCart))
          let itemData = {
            "cart_items": currentCart,
            "cart_calculated": {
              "coin_by_payment_channel": {
                "scb_qr_code": 0,
                "scb_easy_app": 0,
                "credit_card": 0
              },
              "num_product_item": 1,
              "sum_quantity": 1,
              "total": total,
              "grand_total": parseFloat(total) + 40,
              "shipping_fee": 40,
              "discount": 0,
              "discount_info": "",
              "discount_percent": 0,
              "count_cart_item": 1,
              "vat": 17,
              "vat_percent": 7,
              "error_message": ""
            },
            "cart_suggests": [],
            "cart_promotions": [],
            "coupon_promotions": [],
            "coupon_code": "",
            "payment_channels": [
              {
                "title": "QR Code",
                "value": "scb_qr_code"
              },
              {
                "title": "SCB Easy App (เฉพาะการทำรายการในโทรศัพท์)",
                "value": "scb_easy_app"
              },
              {
                "title": "บัตรเครดิต / บัตรเดบิต (การจ่ายเงินด้วยบัตรเครดิตจะไม่ได้รับ coins)",
                "value": "credit_card"
              }
            ],
            "GBPAY_PUBLIC_KEY": "GXxdXHafevc1wGglODm3zsE3qcAbaa0f",
            "GBPAY_API_BASE_URL": "https://api.globalprimepay.com",
            "coin": 0,
            "is_branch_staff": false
          }
          localStorage.setItem('itemData', JSON.stringify(itemData))
          commit('SET_DATA', currentCart)
          window.location.reload()
        }
    },
    async fetchDecreaseProduct({commit}, data) {
      if(data.user!= undefined){
        const response = await cartApi.decreaseProduct({
          product_id: data.product_id
        })
        commit('SET_DATA', response)
     }else{
      let currentCart = JSON.parse(localStorage.getItem('cartItem'))
          let total = 0
          currentCart.forEach(element => {
            if(element.product.name == data.product_name){
              element.amount--
              if(element.amount <= 0){
                currentCart.splice(currentCart.indexOf(element), 1)
              }else{
                total += element.price * element.amount
              }
            }else{
              total += element.price * element.amount
            }
            
          })
          localStorage.setItem('cartItem', JSON.stringify(currentCart))
          let itemData = {
            "cart_items": currentCart,
            "cart_calculated": {
              "coin_by_payment_channel": {
                "scb_qr_code": 0,
                "scb_easy_app": 0,
                "credit_card": 0
              },
              "num_product_item": 1,
              "sum_quantity": 1,
              "total": total,
              "grand_total": parseFloat(total) + 40,
              "shipping_fee": 40,
              "discount": 0,
              "discount_info": "",
              "discount_percent": 0,
              "count_cart_item": 1,
              "vat": 17,
              "vat_percent": 7,
              "error_message": ""
            },
            "cart_suggests": [],
            "cart_promotions": [],
            "coupon_promotions": [],
            "coupon_code": "",
            "payment_channels": [
              {
                "title": "QR Code",
                "value": "scb_qr_code"
              },
              {
                "title": "SCB Easy App (เฉพาะการทำรายการในโทรศัพท์)",
                "value": "scb_easy_app"
              },
              {
                "title": "บัตรเครดิต / บัตรเดบิต (การจ่ายเงินด้วยบัตรเครดิตจะไม่ได้รับ coins)",
                "value": "credit_card"
              }
            ],
            "GBPAY_PUBLIC_KEY": "GXxdXHafevc1wGglODm3zsE3qcAbaa0f",
            "GBPAY_API_BASE_URL": "https://api.globalprimepay.com",
            "coin": 0,
            "is_branch_staff": false
          }
          localStorage.setItem('itemData', JSON.stringify(itemData))
          commit('SET_DATA', currentCart)
          window.location.reload()
     }
    },
    async fetchApplyCoupon({commit, dispatch}, code) {
      try {
        const response = await cartApi.applyCoupon({
          coupon_code: code
        })
        commit('SET_COUPON', response)
        commit('SET_COUPON_CODE', code)
      }
      catch (err) {
        commit('snackBar/SET_DATA', {message: err.detail, color: 'error'}, {root: true})
        commit('SET_COUPON_CODE', '')
        dispatch('fetchRemoveCoupon')
      }
    },
    async fetchRemoveCoupon({commit}) {
      try {
        const response = await cartApi.removeCoupon()
        commit('SET_COUPON_CODE', '')
        commit('SET_COUPON', response)
      }
      catch (err) {
        commit('snackBar/SET_DATA', {message: err.detail, color: 'error'}, {root: true})
      }
    },
    async fetchCreateOrder({commit}, body) {
      if(body.getUser != null){
        const response = await cartApi.createOrder(body)
        commit('order/SET_CURRENT_ORDER', response, {root: true})
        router.replace(`/my-orders/${response.id}?redirect=1`)
        
      }else{

        body.cart = JSON.parse(localStorage.getItem('itemData'))
        body.address = JSON.parse(localStorage.getItem('userAddress'))[0]
        body.gbpay_token = null
        const response = await cartApi.createOrderFree(body)
        commit('order/SET_CURRENT_ORDER', response, {root: true})
        router.replace(`/my-orders/${response.id}?redirect=1`)
      }
     
    },
    async fetchCreateOrderCreditCard({state, commit, rootGetters}) {
      const address = rootGetters['user/getSelectedAddress']
      const gbPayToken = state.data?.gbPayToken?.card?.token
      const body = {
        address_id: address.id,
        payment_channel: 'credit_card',
        gbpay_token: gbPayToken,
        coupon_code: state.data?.coupon_code
      }
      let response = undefined
      
      try{
        response = await cartApi.createOrder(body)
      }catch{
        body.cart = JSON.parse(localStorage.getItem('itemData'))
      body.address = JSON.parse(localStorage.getItem('userAddress'))[0]
      body.email = localStorage.getItem("userEmail")
      response = await cartApi.createOrderFree(body)
      }
      
      const response3dSecured = await gbPrimePayApi.post3dSecured(response.gbp_reference_number)
      commit('order/SET_CURRENT_ORDER', response, {root: true})
      commit('order/SET_OTP_TEMPLATE', response3dSecured, {root: true})
      router.replace(`/credit-card/otp`)
    },
    async fetchGetGbPayToken({commit, dispatch}, body) {
      const response = await gbPrimePayApi.getGbPayToken(body)
      if (response.resultCode === '00') {
        commit('SET_GB_PAY_TOKEN', response)
        dispatch('fetchCreateOrderCreditCard')
      }
      else if (response.resultCode === '54') {
        commit('snackBar/SET_DATA', {message: i18n.global.t('creditCard.errs.cardExpired'), color: 'error'}, {root: true})
      }
      else if (response.resultCode === '02' || response.resultCode === '42') {
        commit('snackBar/SET_DATA', {message: i18n.global.t('creditCard.errs.cardInvalid'), color: 'error'}, {root: true})
      }
      else {
        commit('snackBar/SET_DATA', {message: i18n.global.t('creditCard.errs.somethingWrong'), color: 'error'}, {root: true})
      }
    }
  }
}
