import storeApi from '@/api/store'
import { parseProduct } from '@/utils'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    data: {
      fetchedProducts: {},
      filteredProducts: null,
      favoriteProducts: null,
      productsByCategoryId: null,
      searchText: '',
      filters: null,
      myMedicine: null
    },
  },
  getters: {
    getProductById: state => id => state.data.fetchedProducts[id],
  },
  actions: {
    async fetchProductById({ commit }, id) {
      const response = await storeApi.getProductById(id)
      const product = parseProduct(response)
      commit('SET_PRODUCT', product)
    },
    async fetchGetFavoriteProducts({ commit }) {
      const response = await storeApi.getFavoriteProducts()
      commit('SET_FAVORITE_PRODUCTS', response)
    },
    async fetchGetProductsByCategoryId({ commit }, id) {
      const response = await storeApi.getProductsByCategoryId(id)
      commit('SET_CATEGORY_PRODUCTS', response)
    },
    async fetchGetProductsByKeywords({ commit }, keywords) {
      const response = await storeApi.getProductsByKeywords(keywords)
      commit('SET_FILTERED_PRODUCTS', response.products)
    },
    async fetchGetMyMedicine({ commit }, type) {
      const response = await storeApi.getMyMedicine(type)
      commit('SET_MY_MEDICINE', { data: response, type })
    },
    async fetchUpdateFavoriteProductById({ state, commit }, { id, is_favorite }) {
      const product = state.data.fetchedProducts[id]
      await storeApi.updateFavoriteProductById(id, is_favorite)
      commit('SET_PRODUCT', {
        ...product,
        is_favorite,
      })
      commit('snackBar/SET_DATA', { message: is_favorite ? i18n.global.t('myFavorite.successAdd') : i18n.global.t('myFavorite.successRemove'), color: 'success' }, { root: true })
    }
  },
  mutations: {
    SET_PRODUCT(state, data) {
      state.data.fetchedProducts[data.id] = data
    },
    SET_FILTERED_PRODUCTS(state, data) {
      state.data.filteredProducts = data
    },
    SET_FAVORITE_PRODUCTS(state, data) {
      state.data.favoriteProducts = data.reduce((sum, product) => {
        return {
          ...sum,
          [product.id]: product
        }
      }, {})
    },
    SET_CATEGORY_PRODUCTS(state, data) {
      state.data.productsByCategoryId = data
    },
    SET_MY_MEDICINE(state, { data, type }) {
      state.data.myMedicine = {
        ...state.data.myMedicine,
        [type]: data
      }
    },
  },
}
