export default (status) => {
  switch (status) {
    case 'ordered':
      return 1
    case 'paid':
      return 2
    case 'shipped':
      return 3
    case 'arrived':
      return 4
    case 'cancelled':
      return 5
    default:
      return 1
  }
}
