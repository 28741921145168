<template>
  <div class="continue-wrapper">
    <img
      v-if="item.thumbnail"
      class="product-img"
      :src="item.thumbnail"
      alt="product-img"
      @click="goToProduct(item)"
    />
    <div class="product-description" @click="goToProduct(item)">
      <p class="name mb-1">{{ item.name }}</p>
      <p class="description">{{ item.description }}</p>
      <p class="description">{{ item.show_dose ? item.show_dose : item.dose_description }}</p>
    </div>
    <div class="how-to-use-wrapper" @click="goToHowTo">
      <img
        class="play-img mb-2"
        src="@/assets/icons/play.svg"
        alt="play-img"
      />
      <p class="how-to-text">วิธีใช้</p>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    onClick: {
      type: Function,
      default: () => null
    }
  },
  setup(props) {
    const router = useRouter()

    /** method */
    const goToHowTo = () => {
      props.onClick(props.item)
    }
    const goToProduct = (product) => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)

    return {
      goToHowTo,
      goToProduct,
    }
  }
}
</script>

<style lang="scss" scoped>

.continue-wrapper {
  border-radius: 6px;
  border: 1px solid #C3C3C3;
  display: flex;
  margin-bottom: 16px;
}

.product-img {
  width: 25%;
  margin: 16px 0 16px 16px;
  border-radius: 6px;
  &:active {
    opacity: 0.7;
  }
}

.product-description {
  border-right: 1px solid #C3C3C3;
  padding: 16px;
  flex: 1;
  &:active {
    opacity: 0.7;
  }
  > .name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  > .description {
    font-size: 1.25rem;
  }
}

.how-to-use-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  &:active {
    opacity: 0.7;
  }
}

.how-to-text {
  font-size: 1.25rem;
  color: #4A58E5;
}
</style>
