<template>
  <div class="continue-drug-modal-wrapper" v-if="updateProductStock">
    <div class="top-header-wrapper">
      <button @click="deselectUpdateProductStock">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ updateProductStock === 'out_of_stock' ? t('myContinueDrugs.unusedDrugs') : t('myContinueDrugs.usingDrugs') }}</p>
    </div>
    <div class="content-wrapper" ref="contentRef">
      <p class="main-header">เลือกรายการยา เพื่อตั้งค่าเป็น<span v-if="updateProductStock === 'out_of_stock'" class="blue">{{ t('myContinueDrugs.usingDrugs') }}</span><span v-else class="red">{{ t('myContinueDrugs.unusedDrugs') }}</span></p>
      <p class="sub-header">{{ updateProductStock === 'out_of_stock' ? 'ย้ายรายการยา เพื่อให้คุณจดจำได้ว่ามีรายการไหนที่ต้องสั่งซื้อเพิ่ม' : 'ย้ายรายการยาที่คุณสั่งซื้อเรียบร้อยแล้วกลับไปที่เดิม' }}</p>
      <div class="select-all mr-a" @click="selectAll">
        <div class="check-box all" :class="{ 'is-checked': selectedDrugs.length === filteredItems?.length }">
          <div class="inside">
            <img src="@/assets/icons/small-check.svg" alt="check">
          </div>
        </div>
        <p class="ml-1" v-if="selectedDrugs.length !== filteredItems?.length">
          {{ t('myDiaryDrugs.selectAll') }}
        </p>
        <p class="ml-1" v-else>
          {{ t('myDiaryDrugs.removeAll') }}
        </p>
      </div>
      <div class="items-wrapper">
        <SelectableContinueDrug
          v-for="item in filteredItems"
          :key="item.id"
          :item="item"
          :selectedDrugs="selectedDrugs"
          :toggleSelectProduct="toggleSelectProduct"
        />
      </div>
      <MButton class="mt-a" fullWidth @click="onClickUpdateProductStock">
        {{ updateProductStock === 'out_of_stock' ? 'ตั้งเป็นยาที่กำลังใช้' : 'ตั้งเป็นรายการยาที่หมดแล้ว' }}
      </MButton>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import MButton from './Form/MButton'
import SelectableContinueDrug from './SelectableContinueDrug'

export default {
  props: {
    updateProductStock: {
      type: String,
    },
    items: {
      type: Array,
      default: () => []
    },
    deselectUpdateProductStock: {
      type: Function,
      default: () => null
    }
  },
  components: {
    MButton,
    SelectableContinueDrug,
  },
  setup(props) {
    const router = useRouter()
    const store = useStore()
    const { t } = useI18n()

    const selectedDrugs = ref([])

    const deviceWidth = computed(() => {
      if (window.innerWidth > 500) return 500

      return window.innerWidth - 32
    })

    const filteredItems = computed(() => props.items.filter(item => item.product_stock_type === props.updateProductStock))

    const goToProduct = (product) => {
      router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)
    }

    const selectAll = () => {
      if (selectedDrugs.value.length === filteredItems.value?.length) {
        selectedDrugs.value = []
      }
      else {
        selectedDrugs.value = filteredItems.value.map(product => product.id)
      }
    }

    const toggleSelectProduct = (item) => {
      const found = selectedDrugs.value.includes(item.id)
      if (found) {
        selectedDrugs.value = selectedDrugs.value.filter(p => p !== item.id)
      }
      else {
        selectedDrugs.value.push(item.id)
      }
    }

    const onClickUpdateProductStock = async () => {
      const data = {
        product_ids: selectedDrugs.value,
        type: props.updateProductStock === 'out_of_stock' ? 'currently_used' : 'out_of_stock'
      }
      await store.dispatch('user/fecthUpdateProductStock', data)
      await store.dispatch('user/fecthHowToUseMyContinueDrugs')
      props.deselectUpdateProductStock()
      selectedDrugs.value = []
    }

    return {
      t,
      goToProduct,
      deviceWidth,
      selectedDrugs,
      selectAll,
      toggleSelectProduct,
      onClickUpdateProductStock,
      filteredItems,
    }
  }
}
</script>

<style lang="scss" scoped>

.continue-drug-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  overflow-y: scroll;
  padding-top: 56px;
}


.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.product-wrapper {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #c3c3c3;
  margin-bottom: 16px;
}

.product-img {
  width: 25%;
  border-radius: 6px;
  &:active {
    opacity: 0.7;
  }
}

.product-description {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  > .name {
    font-size: 2rem;
    font-weight: bold;
  }
  > .description {
    font-size: 1.5rem;
  }
}

.video-tag {
  object-fit: fill;
  margin: 0 auto;
}

.detail-wrapper {
  padding: 16px;
  margin: 16px 0;
  border: 1px solid #c3c3c3;
  border-radius: 6px;
  > .row {
    display: flex;
    .detail-title {
      font-weight: bold;
    }
    > p {
      flex: 1;
      font-size: 1.25rem;
    }
  }
}

.red {
  color: rgba(244, 67, 54, 1);
}

.blue {
  color: rgba(74, 88, 229, 1);
}

.main-header {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  > span {
    font-size: 1.25rem;
  }
  margin-bottom: 8px;
}

.sub-header {
  text-align: center;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--grey-color);
  margin-bottom: 12px;
}

.items-wrapper {
  padding: 12px 0;
}

.check-box {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid var(--secondary-color);
  border-radius: 4px;
  overflow: hidden;
  &.all {
    position: static;
    // top: inherit;
    // right: inherit;
  }
  .inside {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    background-color: var(--secondary-color);
    transition: all 0.2s ease;
    > img {
    }
  }
  &.is-checked {
    border: 2px solid var(--secondary-color);
    > .inside {
      transform: scale(1);
    }
  }
}

.select-all {
  display: flex;
  align-items: center;
  color: #000;
}
</style>