import userApi from '@/api/user'
import storeApi from '@/api/store'
import router from '@/router'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    address: null,
    addresses: [],
    data: null,
    profile: null,
    continueDrugs: [],
    howToUseMyContinueDrugs: [],
    requestIdOtp: null,
    branches: [],
    showVerify: false,
    provinces: [],
    districts: [],
    subDistricts: [],
    notFound: false,
  },
  getters: {
    getUser: state => state.data,
    getUserProfile: state => state.profile,
    getMyContinueDrugs: state => state.continueDrugs || [],
    getHowToUseMyContinueDrugs: state => state.howToUseMyContinueDrugs || [],
    getSelectedAddress: state => {
   
      const foundSelected = state.addresses.find(address => address.isSelected)
      if (foundSelected) return foundSelected
      const primary = state.addresses.find(address => address.is_primary)
      
      return primary
    },
    getBranchOptions: state => state.branches.map(branch => ({ ...branch, value: branch.id, label: branch.name }))
  },
  actions: {
    async fetchLoginWithLine({ commit }, { lineAccessToken, s }) {
      const response = await userApi.loginWithLine(lineAccessToken, s)
      commit('SET_USER', response)
    },
    async fetchLoginWithUsername({ commit }, { username, password }) {
      const response = await userApi.loginWithUsername(username, password)
      commit('SET_USER', response)
    },
    async fetchGetUserProfile({ commit, state }, redirect) {
      if (!state.data) {
        return
      }
      const response = await userApi.getProfileUser()
      const showVerify = sessionStorage.getItem('is_show')
      const isOnVerify = window.location.href.includes('/verify')
      if (!response.is_verified_phone && !response.makalin_user_id && !showVerify) {
        router.push('/user/verify')
        sessionStorage.setItem('is_show', true)
        return
      }
      commit('SET_USER_PROFILE', response)
      if (isOnVerify) return
      const redirectTo = sessionStorage.getItem('redirectTo')
      if (redirectTo) {
        sessionStorage.removeItem('redirectTo')
        window.location.href = redirectTo
      }
      else if (redirect) {
        router.replace(redirect)
      }
    },
    async fetchUpdateMakalinPhone({ commit }, phone) {
      const response = await userApi.updateMakalinPhone(phone)
      const userProfileresponse = await userApi.getProfileUser()
      commit('SET_USER_PROFILE', userProfileresponse)

      return response
    },
    async fetchUpdateUser({ commit }, body) {
      const response = await userApi.updateUser(body)
      commit('SET_USER_PROFILE', response)
      commit('snackBar/SET_DATA', { message: i18n.global.t('successUpdateProfile'), color: 'success' }, { root: true })
    },
    async fetchUpdateAvatar({ commit }, { file, callback }) {
      const response = await userApi.updateAvatar(file)
      callback()
      commit('SET_USER_PROFILE', response)
      commit('snackBar/SET_DATA', { message: i18n.global.t('successUpdateProfile'), color: 'success' }, { root: true })
    },
    async fetchVerifyLaserOTP({ commit, state }, { otp, name, productId }) {
      const response = await userApi.verifyLaserOTP(otp, state.requestIdOtp, productId, name)
      commit('SET_REQUEST_ID_OTP', response)
    },
    async fetchGetLaserOTP({ commit }, { phone }) {
      const response = await userApi.getLaserOTP(phone)
      commit('SET_REQUEST_ID_OTP', response.request_id)
    },
    async fetchVerifyOTP({ commit, state }, otp) {
      const response = await userApi.verifyOTP(otp, state.requestIdOtp)
      commit('SET_USER', response)
    },
    async fetchGetOTP({ commit }, { phone, branchId }) {
      const response = await userApi.getOTP(phone, branchId)
      commit('SET_REQUEST_ID_OTP', response.request_id)
    },
    async fetchVerifyUserOTP({ commit, state }, otp) {
      try {
        const response = await userApi.verifyUserOTP(otp, state.requestIdOtp)
        commit('snackBar/SET_DATA', { message: response.detail, color: 'success' }, { root: true })
        router.replace('/my-profile')
      }
      catch (err) {
        commit('snackBar/SET_DATA', { message: err.detail, color: 'error' }, { root: true })
      } 
    },
    async fetchGetVerifyUserOTP({ commit }, { phone, branchId }) {
      try {
        const response = await userApi.getVerifyUserOTP(phone, branchId)
        commit('SET_REQUEST_ID_OTP', response.request_id)
      }
      catch (err) {
        if (err.status === 404) {
          commit('SET_NOT_FOUND', true)
          commit('snackBar/SET_DATA', { message: err.detail, color: 'warning' }, { root: true })
        }
      }
    },
    async fetchResetPassword({ commit, state }, { newPassword, reNewPassword }) {
      await userApi.setNewPassword(newPassword, reNewPassword, state.requestIdOtp)
      commit('snackBar/SET_DATA', { message: i18n.global.t('successUpdatePassword'), color: 'success' }, { root: true })
      router.replace('/user/login')
    },
    async fetchVerifyForgetPasswordOTP({ state }, otp) {
      await userApi.verifyForgetPasswordOTP(otp, state.requestIdOtp)
      router.push('/user/forget-password/new')
    },
    async fetchGetForgetPasswordOTP({ commit }, phone) {
      try {
        const response = await userApi.getForgetPasswordOTP(phone)
        commit('SET_REQUEST_ID_OTP', response.request_id)
      }
      catch (err) {
        commit('snackBar/SET_DATA', { message: err.detail, color: 'error' }, { root: true })
      } 
    },
    async fetchMyContinueDrugs({ commit }) {
      try {
        const data = await userApi.getMyContinueDrugs()
        commit('SET_CONTINUE_DRUGS', data?.continue_drug_products || [])
      }
      catch (err) {
        commit('snackBar/SET_DATA', { message: i18n.global.t('loginFirst'), color: 'warning' }, { root: true })
        router.replace('/user/login')
      }
    },
    async fecthHowToUseMyContinueDrugs({ commit }) {
      try {
        const data = await userApi.getHowToUseMyContinueDrugs()
        commit('SET_HOW_TO_USE_CONTINUE_DRUGS', data || [])
      }
      catch (err) {
        sessionStorage.setItem('redirectTo', window.location.href)
        commit('snackBar/SET_DATA', { message: i18n.global.t('loginFirst'), color: 'warning' }, { root: true })
        router.replace('/user/login')
      }
    },
    async fetchGetAddress({ commit }, id) {
      const data = await userApi.getAddress(id)
      commit('SET_ADDRESS', data)
    },
    async fetchGetAddresses({ commit }) {
      try{
        const data = await userApi.getAddresses()
        console.log(data)
        commit('SET_ADDRESSES', data)
      }catch(err){
        let data =  localStorage.getItem('userAddress')
   
        if(Array.isArray(JSON.parse(data))){
          commit('SET_ADDRESSES',  JSON.parse(data))
        }
       
      }
     
    },
    async fetchAddNewAddress({ commit }, body) {
      console.log('body' , body)
      try{
        await userApi.addNewAddress(body)
        commit('snackBar/SET_DATA', { message: i18n.global.t('myAddresses.successAdd'), color: 'success' }, { root: true })
        router.replace('/my-addresses')
      }catch(err){
        console.log('err' , err)
        router.replace('/my-addresses')
        
        let data = [
          {
              "id": 20,
              "address_name": body.address_name,
              "name": body.name,
              "full_address": body.address_number +  " " + body.address_sub_district + " " + body.address_district + " " + " จ." + body.address_province + " " + body.address_postcode,
              "phone": body.phone,
              "address_postcode": body.address_postcode,
              "is_primary": true
          }
      ]
        localStorage.setItem('userAddress', JSON.stringify(data))
        
      }

    

    },
    async fetchUpdateAddress({ commit }, { id, body }) {
      await userApi.updateAddress(id, body)
      commit('snackBar/SET_DATA', { message: i18n.global.t('myAddresses.successUpdate'), color: 'success' }, { root: true })
      router.replace('/my-addresses')
    },
    async fetchDeleteAddress({ commit }, id) {
      await userApi.deleteAddress(id)
      const data = await userApi.getAddresses()
      commit('snackBar/SET_DATA', { message: i18n.global.t('myAddresses.successDelete'), color: 'success' }, { root: true })
      commit('SET_ADDRESSES', data)
    },
    async fetchGetBranches({ commit }) {
      const data = await userApi.getBranches()
      commit('SET_BRANCHES', data)
    },
    async fetchGetTermAndPrivacy({ commit }) {
      const data = await userApi.getTermAndPrivacy()
      commit('SET_TERM_DESC', data)
    },
    async fetchAcceptPrivacy({ commit }) {
      await userApi.acceptPrivacy()
      commit('SET_TERM_DESC', null)
    },
    async fetchGetProvince({ commit }) {
      commit('SET_DISTRICTS', [])
      commit('SET_SUB_DISTRICTS', [])
      const items = await userApi.getProvince()
      commit('SET_PROVINCES', items)
    },
    async fetchGetDistrict({ commit }, provinceCode) {
      commit('SET_SUB_DISTRICTS', [])
      if (provinceCode) {
        const items = await userApi.getDistrict(provinceCode)
        commit('SET_DISTRICTS', items)
      }
      else {
        commit('SET_DISTRICTS', [])
      }
    },
    async fetchGetSubDistrict({ commit }, districtCode) {
      if (districtCode) {
        const items = await userApi.getSubDistrict(districtCode)
        commit('SET_SUB_DISTRICTS', items)
      }
      else {
        commit('SET_SUB_DISTRICTS', [])
      }
    },
    async fecthUpdateProductStock(context, data) {
      await storeApi.updateProductStock(data)
    },
  },
  mutations: {
    SET_SHOW_VERIFY(state, data) {
      state.showVerify = data
    },
    RESET_REQUEST_ID_OTP(state) {
      state.requestIdOtp = null
    },
    SET_REQUEST_ID_OTP(state, data) {
      state.requestIdOtp = data
    },
    SET_USER(state, data) {
      state.data = { ...state.data, ...data }
    },
    RESET_USER(state) {
      state.data = null
      state.profile = null
    },
    SET_USER_PROFILE(state, data) {
      state.profile = data
    },
    SET_NOT_FOUND(state, data) {
      state.notFound = data
    },
    SET_CONTINUE_DRUGS(state, data) {
      state.continueDrugs = data
    },
    SET_HOW_TO_USE_CONTINUE_DRUGS(state, data) {
      state.howToUseMyContinueDrugs = data
    },
    SET_ADDRESSES(state, data) {
      const foundSelected = state.addresses.find(address => address.isSelected)
      if (foundSelected) {
        state.addresses = data.map(address => {
          if (address.id === foundSelected.id) {
            return {
              ...address,
              isSelected: true,
            }
          }
          return address
        })
      }
      else {
        state.addresses = data
      }
    },
    SET_ADDRESS(state, data) {
      state.address = data
    },
    RESET_ADDRESS(state) {
      state.address = null
    },
    SET_BRANCHES(state, data) {
      state.branches = data
    },
    SET_TERM_DESC(state, data) {
      state.termDescription = data
    },
    SET_PROVINCES(state, data) {
      state.provinces = data
    },
    SET_DISTRICTS(state, data) {
      state.districts = data
    },
    SET_SUB_DISTRICTS(state, data) {
      state.subDistricts = data
    },
    SET_SELCETED_ADDRESS(state, id) {
      state.addresses = state.addresses.map(address => {
        if (id === address.id) {
          return {
            ...address,
            isSelected: true,
          }
        }
        return {
          ...address,
          isSelected: false,
        }
      })
    }
  },
}
