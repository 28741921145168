<template>
  <div class="wrapper">
    <slot />
  </div>
</template>

<script></script>

<style lang="scss" scoped>
.wrapper {
  background-color: #E6E6E6;
  padding: 4px 12px;
  border-radius: 32px;
}
</style>