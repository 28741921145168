<template>
  <div class="product-header-menu">
    <p class="title">
      {{ title }}
    </p>
    <div v-if="end" class="end-timer">
      <p class="box">{{ hours }}</p>:
      <p class="box">{{ minutes }}</p>:
      <p class="box">{{ seconds }}</p>
    </div>
    <p v-if="seeAll" class="see-all">
      All >
    </p>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import dayjs from 'dayjs'

export default {
  props: {
    end: {
      default: null,
    },
    title: {
      type: String,
      require: true,
    },
    seeAll: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    /** reactive */
    const timer = ref(null)
    const now = ref(dayjs())
    const hours = ref('00')
    const minutes = ref('00')
    const seconds = ref('00')

    /** mounted */
    onMounted(() => {
      if (props.end) {
        timer.value = setInterval(() => {
          now.value = dayjs()
          const diff = props.end.diff(now.value)
          hours.value = dayjs(diff).format('hh')
          minutes.value = dayjs(diff).format('mm')
          seconds.value = dayjs(diff).format('ss')
        })
      }
    })

    /** unmounted */
    onUnmounted(() => {
      if (timer.value) {
        clearInterval(timer.value)
      }
    })
  }
}
</script>

<style lang="scss" scoped>

.product-header-menu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
}

.title {
  font-size: 1.4rem;
  font-weight: bold;
}

.end-timer {
  flex: 1;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  margin-left: 20px;
  > .box {
    background-color: #FFDF26;
    padding: 2px 4px;
    border-radius: 4px;
    margin: 0 2px;
  }
}

.see-all {
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.2rem;
  &:active {
    opacity: 0.8;
  }
}
</style>
