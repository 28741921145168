<template>
  <div class="profile-card">
    <img :src="user?.thumbnail_url || require('@/assets/logo.jpg')" alt="profile" class="profile-img">
    <div class="detail-wrapper" v-if="user">
      <div class="name">
        {{ user.display_name ? user.display_name : `${user?.first_name} ${user?.last_name}` }}
        <div class="connected">
          <img v-if="user.is_verified_phone || user?.makalin_user_id" class="verify" src="@/assets/icons/check-decagram.svg" alt="verify">
          {{ user.is_verified_phone || user?.makalin_user_id ? 'Connected' : '' }}
        </div>
      </div>
      <p class="email">{{ user?.email }}</p>
      <p class="edit-profile" @click="goToEditProfile">{{ t('profile.editProfile') }}</p>
    </div>
    <div v-else class="no-user">
      <p class="login" @click="goToLogin">{{ t('profile.login') }}</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** computed */
    const user = computed(() => store.getters['user/getUserProfile'])

    /** method */
    const goToEditProfile = () => router.push('/edit-profile')
    const goToLogin = () => router.push('/user/login')

    return {
      t,
      user,
      goToEditProfile,
      goToLogin
    }
  }
}
</script>

<style lang="scss" scoped>

.profile-card {
  display: flex;
}

.profile-img {
  width: 64px;
  height: 64px;
  border-radius: 64px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

.detail-wrapper {
  margin-left: 16px;
}

.name {
  font-size: 1.4rem;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  > .connected {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    font-size: 24px;
  }
}

.email {
  font-size: 1.2rem;
  margin-bottom: 4px;
  color: #6A6A6A;
}

.no-user {
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.edit-profile, .login {
  font-size: 1.2rem;
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}

.verify {
  width: 24px;
  margin: 0 4px;
}
</style>
