<template>
  <div class="locale-changer">
    <div class="select-wrapper" @click="toggleShow">
      <img class="flag" :src="require(`@/assets/icons/${locale}-flag.svg`)" alt="lang-flag">
      <img :class="`down${show ? ' active' : ''}`" src="@/assets/icons/down.svg" alt="down">
    </div>
    <div v-if="show" class="drop-down-wrapper">
      <div
        v-for="lang in langs"
        :key="lang"
        :class="`dropdown-item${locale === lang ? ' selected' : ''}`"
        @click="changeLang(lang)"
      >
        <img class="flag" :src="require(`@/assets/icons/${lang}-flag.svg`)" alt="lang-flag">
        <p>{{ lang.toUpperCase() }}</p>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    /** context */
    const { locale } = useI18n()
    /** constant */
    const langs = [ 'th', 'en', 'jp', 'cn', 'km']

    /** reactive */
    const show = ref(false)

    /** method */
    const toggleShow = () => {
      show.value = !show.value
    }

    const changeLang = (lang) => {
      locale.value = lang
      localStorage.setItem('language', lang)
      toggleShow()
      window.location.reload()
    }

    return {
      langs,
      show,
      locale,
      changeLang,
      toggleShow,
    }
  }
}
</script>

<style lang="scss" scoped>

.locale-changer {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.select-wrapper {
  display: flex;
  align-items: center;
}

.down {
  margin-left: 8px;
  transition: transform 0.2s ease-in-out;
  &.active {
    transform: rotateX(180deg);
  }
}

.drop-down-wrapper {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 7;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

.dropdown-item {
  display: flex;
  padding: 4px 8px;
  background-color: var(--white-color);
  &:active {
    background-color: var(--secondary-color);
    > p {
      color: var(--white-color);
    }
  }
  &.selected {
    background-color: #21bcb8;
    > p {
      color: var(--white-color);
    }
  }
  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #333333;
  }
  > p {
    margin-left: 8px;
    font-weight: bold;
  }
}

.flag {
  width: 18px;
  height: 18px;
}
</style>
