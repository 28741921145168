<template>
  <div class="product-in-cart-card-wrapper">
    <img :src="item?.product?.thumbnail" alt="product" class="product">
    <div class="detail-wrapper">
      <p class="title">{{ item?.product.name }}</p>
      <p class="price">฿{{ item?.product.price }}</p>
    </div>
    <div class="amount"><small>X</small> {{ amount }}</div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    }
  },
  setup(props) {

    /** reactive */
    const amount = ref(props.item?.amount)

    return {
      amount,
    }
  }
}
</script>

<style lang="scss" scoped>
.product-in-cart-card-wrapper {
  border: 1px solid #C3C3C3;
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
}

img.product {
  height: 32px;
  width: 32px;
  border-radius: 6px;
}

.detail-wrapper {
  margin-left: 12px;
}

.title {
  font-size: 20px;
}

.price {
  color: var(--error-color);
  font-size: 20px;
  font-weight: bold;
}

.amount {
  margin-left: auto;
  > small {
    font-size: 0.8em;
  }
}

</style>
