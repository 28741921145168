<template>
  <div class="radio-container" @click="$emit('update:modelValue', value)">
    <div class="radio-box main-box-shadow" :class="{ 'checked' : modelValue === value }"></div>
    <span class="label">{{ label }}</span>
  </div>
</template>

<script>

export default {
  name: 'RadioInput',
  props: {
    modelValue: {
      type: null
    },
    label: {
      type: String,
    },
    value: {
      type: null
    }
  }
}
</script>

<style lang="scss" scoped>

.radio-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
}

.radio-box {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: var(--primary-color);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.1s ease;
  }
  &.checked {
    &::before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.label {
  margin-left: 12px;
}
</style>
