<template>
  <div class="login-wrapper">
    <p class="back" @click="backToLogin" v-if="step === 1">&lt; {{ t('back') }}</p>
    <p class="title">{{ t('forgetPassword.title') }}</p>
    <div class="log-in-with-phone" v-if="step === 1">
      <p class="description">
        {{ t('forgetPassword.pleaseFillPhone') }}<br/>
        {{ t('forgetPassword.forVerify') }}
      </p>
      <TextInput v-model="phone" :label="t('phone')" />
      <MButton @click="getOTP">{{ t('getOTP') }}</MButton>
    </div>
    <p class="back" @click="back" v-if="step === 2">&lt; {{ t('back') }}</p>
    <div class="input-otp" v-if="step === 2">
      <p class="description">
        {{ t('forgetPassword.pleaseFillOtp') }}
      </p>
      <TextInput autofocus v-model="otp" :label="t('otp')" />
      <MButton @click="verifyOTP">{{ t('confirm') }}</MButton>
      <p v-if="second > 0" class="didnt-get-otp mt-2">{{  t('didntGetOtp') }} ({{ t('otpCounter', { second }) }})</p>
      <p @click="getOTP" class="resend-otp mt-2" v-if="second === 0">{{  t('resendOtp') }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed, watchEffect, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import MButton from '@/components/Form/MButton'
import TextInput from '@/components/Form/TextInput'

export default {
  name: 'ForgetPassword',
  components: {
    MButton,
    TextInput,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** reactive */
    const phone = ref('')
    const otp = ref('')
    const step = ref(1)
    const second = ref(0)
    const counter = ref(null)

    /** computed */
    const requestIdOtp = computed(() => store.state.user.requestIdOtp)

    /** method */
    const getOTP = () => {
      store.dispatch('user/fetchGetForgetPasswordOTP', phone.value)
    }
    const verifyOTP = () => {
      store.dispatch('user/fetchVerifyForgetPasswordOTP', otp.value)
    }
    const back = () => {
      step.value--
      store.commit('user/RESET_REQUEST_ID_OTP')
    }
    const backToLogin = () => {
      router.replace('/user/login')
    }

    /** mounted */
    onMounted(() => {
      store.commit('user/RESET_REQUEST_ID_OTP')
    })

    /** watch */
    watchEffect(() => {
      if (requestIdOtp.value) {
        step.value = 2
        second.value = 60
      }
      else {
        second.value = 0
        step.value = 1
      }
    })
    watchEffect(() => {
      if (second.value === 60) {
        counter.value = setInterval(() => {
          second.value--
        }, 1000)
      }
      if (second.value === 0 && counter.value) {
        clearInterval(counter.value)
      }
    })

    return {
      t,
      getOTP,
      verifyOTP,
      phone,
      requestIdOtp,
      step,
      back,
      backToLogin,
      second
    }
  }
}
</script>

<style lang="scss" scoped>

.login-wrapper {
  background-color: var(--background-color);
  background-image: url('@/assets/images/bg-logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 12px 28px;
}

.log-in-with-phone {
  padding: 0 40px;
}

button {
  width: 100%;
}

.back {
  color: var(--secondary-color);
  margin-bottom: 12px;
  font-size: 1.2rem;
  padding: 12px;
  cursor: pointer;
}

.description {
  color: var(--secondary-color);
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 24px;
}

.title {
  text-align: center;
  font-size: 1.6rem;
  margin-bottom: 24px;
  color: var(--primary-color);
}

.didnt-get-otp {
  color: var(--secondary-color);
}
.resend-otp {
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}
</style>
