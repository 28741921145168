import api from '@/api'

const getCart = async () => {
  const result = await api.get('/api/cart/')
  
  return result
}

const addProduct = async (body) => {
  const result = await api.post('/api/cart/add_product', body)
  
  return result
}

const decreaseProduct = async (body) => {
  const result = await api.post('/api/cart/decrease_product', body)
  
  return result
}

const applyCoupon = async (body) => {
  const result = await api.post('/api/cart/apply_coupon', body, { loading: false })
  
  return result
}

const removeCoupon = async () => {
  const result = await api.post('/api/cart/remove_coupon', null, { loading: false })
  
  return result
}

const createOrder = async (body) => {
  const result = await api.post('/api/cart/create_order', body)

  return result
}

const createOrderFree = async (body) => {
  const result = await api.post('/api/cart/create_order_free', body)

  return result
}

export default {
  getCart,
  addProduct,
  decreaseProduct,
  applyCoupon,
  removeCoupon,
  createOrder,
  createOrderFree,
}
