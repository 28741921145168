<script>
import { ref, computed, onMounted, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'

import TextInput from '@/components/Form/TextInput'
import MButton from '@/components/Form/MButton'
// import DropdownInput from '@/components/Form/DropdownInput'

export default {
  name: 'laserReserve',
  components: {
    TextInput,
    MButton,
    // DropdownInput,
  },
  setup() {
    const { t, locale } = useI18n()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    
    /** constant */
    const user = computed(() => store.getters['user/getUserProfile'])
    const selectedBranch = ref({})
    const id = ref(route.params.id)
    const name = ref('')
    const phone = ref('')
    const memberPhone = ref('')
    const isValid = computed(() => name.value && phone.value)
    const step = ref(1)
    const second = ref(0)
    const otp = ref('')
    const showSuccessModal = ref(false)
    const showMemberModal = ref(false)
    const requestIdOtp = computed(() => store.state.user.requestIdOtp)
    const otpSuccess = computed(() => store.state.user?.data?.success)
    /** computed */
    const product = computed(() => store.getters['products/getProductById'](id.value))
    const branches = computed(() => store.getters['user/getBranchOptions'])
    /** mounted */
    onMounted(() => {
      store.dispatch('user/fetchGetUserProfile')
      store.dispatch('user/fetchGetBranches')
      store.dispatch('products/fetchProductById', id.value)
      store.commit('user/RESET_REQUEST_ID_OTP')
      showSuccessModal.value = false
    })

    const back = () => {
      router.back()
    }

    const backToMain = () => {
      router.replace('/')
    }

    const resetOtp = () => {
      store.commit('user/RESET_REQUEST_ID_OTP')
    }

    const getOTP = () => {
      // store.dispatch('user/fetchGetLaserOTP', { phone: phone.value, branchId: selectedBranch.value.value })
      store.dispatch('user/fetchGetLaserOTP', { phone: phone.value })
    }

    const verifyOTP = () => {
      store.dispatch('user/fetchVerifyLaserOTP', {
        otp: otp.value,
        name: name.value,
        productId: id.value,
      })
    }

    const closeModal = () => {
      showMemberModal.value = false
    }

    const goToVerify = () => {
      store.commit('user/SET_SHOW_VERIFY', false)
      router.push('/user/verify')
    }

    const goToLogin = () => {
      sessionStorage.setItem('redirectTo', window.location.href)
      router.push('/user/login')
    }

    watchEffect(() => {
      if (typeof requestIdOtp.value === 'string') {
        step.value = 2
        second.value = 60
      }
      else {
        second.value = 0
        step.value = 1
      }
    })
    watchEffect(() => {
      if (user?.value?.display_name && user?.value?.phone) {
        name.value = user?.value?.display_name
        phone.value = user?.value?.phone
      }
    })

    watchEffect(() => {
      if (typeof requestIdOtp.value === 'object' && requestIdOtp.value?.patrick_booking_no) {
        window.location.href = `${process.env.VUE_APP_BOOKING_WEB_URL}?callbackUrl=${process.env.VUE_APP_URL}&type=${product.value?.laser_type}&bookingNo=${requestIdOtp.value?.patrick_booking_no}`
      }
    })

    return {
      t,
      locale,
      user,
      product,
      name,
      phone,
      isValid,
      back,
      getOTP,
      requestIdOtp,
      step,
      otp,
      verifyOTP,
      otpSuccess,
      showSuccessModal,
      showMemberModal,
      backToMain,
      memberPhone,
      closeModal,
      branches,
      selectedBranch,
      resetOtp,
      goToVerify,
      goToLogin,
    }
  }
}
</script>

<template>
  <div class="container">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
        <p>{{ t('back') }}</p>
      </button>
    </div>
    <p class="title">
      {{ t('laserService.reserverTitle') }}
    </p>
    <div class="laser-package-detail">
      <p class="package-title">
        {{ t('laserService.packageTitle') }}:
      </p>
      <div class="laser-detail-wrapper">
        <img :src="product?.thumbnail" alt="logo" class="image-wrapper">
        <div class="content-wrapper">
          <p class="laser-name">{{ product?.name[locale] }}</p>
          <p class="laser-description">{{ product?.description }}</p>
        </div>
      </div>
    </div>
    <div v-if="step === 1">
      <div class="no-phone-wrapper" v-if="!user?.is_verified_phone || !user?.makalin_user_id">
        <img src="@/assets/icons/m-coin.svg" alt="logo" class="logo">
        <div class="member-title mb-2">{{ t('laserService.memberTitle') }}</div>
        <MButton fullWidth @click="goToLogin" v-if="!user">
          <img src="@/assets/icons/link.svg" alt="logo" class="link">
          {{ t('laserService.connectMakalin') }}
        </MButton>
        <MButton fullWidth @click="goToVerify" v-else-if="!user?.is_verified_phone || !user?.makalin_user_id">
          <img src="@/assets/icons/link.svg" alt="logo" class="link">
          {{ t('laserService.connectMakalin') }}
        </MButton>
      </div>
      <div v-else>
        {{ name }}
        <TextInput v-model="name" :label="t('laserService.reserverName')" required />
        <TextInput v-model="phone" :label="t('laserService.reserverPhone')" required />
        <!-- <DropdownInput
          :label="t('branch')"
          v-model="selectedBranch"
          name="branch"
          :items="branches"
        /> -->
        <div class="bottom-wrapper">
          <MButton fullWidth :disabled="!isValid" @click="getOTP">{{ t('laserService.getOTP') }}</MButton>
        </div>
      </div>
    </div>
    <div v-if="step === 2">
      <p class="confirm-title">{{ t('laserService.reserverName') }}:</p>
      <p class="confirm-detail mb-2">{{ name }}</p>
      <p class="confirm-title">{{ t('laserService.reserverPhone') }}:</p>
      <p class="confirm-detail">{{ phone }}</p>
      <p class="edit mt-2" @click="resetOtp">{{ t('edit') }}</p>
      <div class="otp-form-wrapper">
        <p class="otp-title">
          {{ t('laserService.fillOTP') }}
        </p>
        <p class="send-otp mb-2">
          {{ t('laserService.sendOTP') }}<br />
          {{ phone }}
        </p>
        <TextInput autofocus v-model="otp" :label="t('otp')" />
      </div>
      <div class="bottom-wrapper">
        <MButton fullWidth :disabled="otp.length !== 4" @click="verifyOTP">{{ t('laserService.confirm') }}</MButton>
      </div>
    </div>
  </div>
  <!-- <div class="success-modal-wrapper" v-if="showSuccessModal">
    <div class="success-card-wrapper">
      <div class="title mb-2">{{ t('laserService.successTitle') }}</div>
      <div class="detail mb-2">{{ t('laserService.successDetail') }}</div>
      <MButton @click="backToMain">{{ t('backToMain') }}</MButton>
    </div>
  </div>
  <div class="success-modal-wrapper" v-if="showMemberModal">
    <div class="success-card-wrapper">
      <div class="title mb-2">{{ t('laserService.successConnectTitle') }}</div>
      <div class="detail mb-1">{{ t('laserService.successConnectDetail') }}</div>
        <img src="@/assets/icons/m-coin.svg" alt="logo" class="logo mb-1">
      <MButton @click="closeModal">{{ t('laserService.backToReserve') }}</MButton>
    </div>
  </div> -->
</template>

<style lang="scss" scoped>

.container {
  padding: 16px;
  padding-top: 48px;
  padding-bottom: 60px;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top-header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
  background-color: var(--white-color);
  > button {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    > img {
      margin-right: 12px;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.package-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.laser-detail-wrapper {
  display: flex;
  border-bottom: 2px solid #C4C4C4;
  padding-bottom: 12px;
  margin-bottom: 12px;
  > .image-wrapper {
    max-width: 80px;
  }
}

.content-wrapper {
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 18px;
  > .laser-name {
    font-weight: bold;
  }
  > .laser-description {
    color: #C4C4C4;
  }
}

.bottom-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 12px;
}

.confirm-title {
  font-weight: bold;
  font-size: 16px;
}
.confirm-detail {
  font-weight: normal;
  font-size: 20px;
}

.no-phone-wrapper {
  background-color: var(--background-color);
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  > .logo {
    width: 50px;
    margin: 0 auto;
  }

  > .member-title {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
  }
  > .member-detail {
    text-align: center;
    font-size: 18px;
  }
}
.otp-form-wrapper {
  background-color: var(--background-color);
  padding: 12px 12px 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  > .otp-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
  }
  > .send-otp {
    text-align: center;
  }
}

.success-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);

  .logo {
    width: 50px;
  }

  > .success-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    > .title {
      font-size: 24px;
      font-weight: bold;
      color: var(--success-color);
    }
    > .detail {
      font-size: 18px;
    }
  }
}

.or-wrapper {
  display: flex;
  align-items: center;
  margin: 12px 0;
  > .text {
    font-size: 24px;
    color: #C4C4C4;
    margin: 0 12px;
  }
  > .line {
    width: 100%;
    height: 2px;
    background-color: #C4C4C4;
  }
}

.link {
  margin: auto 12px auto 0;
}

.edit {
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
}
</style>
