<template>
  <div class="main-tab-bar">
    <div @click="goTo(m)" :class="`menu${m.activePaths.includes($route.name) ? ' active' : ''}`" v-for="m in menu" :key="m.name">
      <img :src="require(`@/assets/icons/menu/${m.icon}${m.activePaths.includes($route.name) ? '-active' : ''}.svg`)" :alt="m.name">
      <p class="title">{{ t(`menu.${m.name}`) }}</p>
      <span class="amount" v-if="m.showBadge && totalItemAmount > 0">
        {{ totalItemAmount }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** constant */
    const menu = [
      {
        name: 'home',
        icon: 'home',
        path: '/',
        activePaths: [
          'Home',
          'Products',
          'ProductById'
        ]
      },
      {
        name: 'myDrugs',
        icon: 'my-drugs',
        path: '/my-drugs',
        activePaths: [
          'LatestReceive',
          'MyDrugs',
          'ContinueDrugs'
        ]
      },
      {
        name: 'myCart',
        icon: 'cart',
        path: '/my-cart',
        showBadge: true,
        activePaths: [
          'MyCart'
        ]
      },
      {
        name: 'profile',
        icon: 'profile',
        path: '/my-profile',
        activePaths: [
          'MyProfile',
          'EditProfile',
          'UserLogin',
          'UserLoginWithPhone'
        ]
      },
    ]
    
    /** computed */
    const totalItemAmount = computed(() => store.getters['cart/totalItemAmount'])

    /** method */
    const goTo = (menu) => router.push(menu.path)

    return {
      t,
      menu,
      goTo,
      totalItemAmount,
    }
  }
}
</script>

<style lang="scss" scoped>

.main-tab-bar {
  position: fixed;
  z-index: 8;
  bottom: 0;
  left: 0;
  right: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  background-color: var(--white-color);
  display: flex;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.08);
}

.menu {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  color: #C4C4C4;
  padding: 10px;
  position: relative;
  cursor: pointer;
  &.active {
    color: var(--secondary-color);
  }
  > img {
    width: 24px;
    height: 24px;
    margin-bottom: 4px;
  }
}

.amount {
  position: absolute;
  border-radius: 50%;
  top: 4px;
  right: 50%;
  transform: translate(120%, 25%);
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: var(--error-color);
  color: var(--white-color);
}
</style>
