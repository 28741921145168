import axios from 'axios'
import store from '@/store'
import i18n from '@/i18n'
import router from '@/router'
// import liff from '@line/liff'

import * as Sentry from '@sentry/vue'

const headers = {
  'Content-Type': 'application/json',
}
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
})

api.interceptors.request.use(
  (config) => {
    const token = store.state?.user?.data?.access
    if (token && !config.noRequiredToken) {
      config.headers = {
        ...headers,
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    }
    const language = i18n.global.locale.value
    if (language && !config.noRequireLanguage) {
      config.headers['Accept-Language'] = language
    }
    if (config.loading !== false) {
      store.commit('loading/SET_DATA', { id: config.url, data: true })
    }
    
    return config
  },
  (error) => {
    store.commit('loading/SET_DATA', { id: error?.config?.url, data: false })
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  async (response) => {
    store.commit('loading/SET_DATA', { id: response?.config?.url, data: false })
    
    return response.data
  },
  async (error) => {
    const originalConfig = error.config
    store.commit('loading/SET_DATA', { id: error?.config?.url, data: false })
    if (error?.response?.data?.detail && error?.response?.status !== 401) {
      store.commit('snackBar/SET_DATA', { message: error?.response?.data?.detail })
    }
    // Refresh Token
    if (!originalConfig.url.includes('/api/user/login') && !originalConfig.url.includes('/api/user/token/refresh') && error.response) {
      const token = error.config.headers?.Authorization
      if (error.response.status === 401) {
        if (token) {
          Sentry.withScope(scope => {
            scope.setExtra('headers', error.config.headers)
            scope.setExtra('user', store.state?.user?.profile)
            Sentry.captureException(error)
          })
          store.commit('user/RESET_USER')
          store.commit('cart/RESET_DATA')
          // store.commit('snackBar/SET_DATA', { message: i18n.global.t('session.expired'), color: 'warning' })
          router.replace('/my-profile')
          // liff.init({
          //   liffId: process.env.VUE_APP_LINE_LIFE_ID
          // }).then(() => {
          //   const isLoggedIn = liff.isLoggedIn()
          //   if (isLoggedIn) {
          //     liff.logout()
          //   }
          // }).catch(err => {
          //   console.log(err)
          //   router.replace('/my-profile')
          // })
        }
        else {
          store.commit('user/RESET_USER')
          store.commit('cart/RESET_DATA')
        }
      }
      // if (error.response.status === 401 && !originalConfig._retry) {
      //   store.commit('user/RESET_USER')
      //   store.commit('cart/RESET_DATA')
      //   if (token) {
      //     store.commit('snackBar/SET_DATA', { message: i18n.global.t('session.expired'), color: 'warning' })
      //     router.replace('/user/login')
      //   }
      //   originalConfig._retry = true
      //   try {
      //     const refresh = store.state.user.data.refresh
      //     const response = await api.post('/api/user/token/refresh', {
      //       refresh,
      //     })
      //     store.commit('SET_USER', response)
      //     return api(originalConfig)
      //   }
      //   catch (err) {
      //     store.commit('user/RESET_USER')
      //     store.commit('cart/RESET_DATA')
      //     return Promise.reject({ ...error.response, ...error.response.data })
      //   }
      // }

    }
    return Promise.reject({ ...error.response, ...error.response.data })
})

export default api