<template>
  <div class="address-card-wrapper" :class="{ 'is-selected': isSelected }">
    <div class="top-wrapper" @click="goToEditAddress(address.id)">
      <p class="address-name">{{ address.address_name }}</p>
      <p class="name">{{ address.name }}</p>
      <p class="full-address">{{ address.full_address }}</p>
      <p class="phone">{{ address.phone }}</p>
    </div>
    <div class="bottom-wrapper">
      <p v-if="address.is_primary && !display" class="main-address">{{ t('myAddresses.mainAddress') }}</p>
      <img v-if="!hiddenRemove" class="remove touchable" src="@/assets/icons/trash.svg" alt="trash" @click="deleteAddress">
    </div>
    <div
      class="check-wrapper"
      v-show="isSelected"
    >
      <img src="@/assets/icons/small-check.svg">
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    address: {
      require: true,
      defaultValue: null,
    },
    hiddenRemove: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    display: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** method */
    const deleteAddress = () => {
      store.dispatch('user/fetchDeleteAddress', props.address.id)
    }
    const goToEditAddress = (id) => !props.hiddenRemove ? router.push(`/my-addresses/${id}/edit`) : store.commit('user/SET_SELCETED_ADDRESS', id)

    return {
      t,
      deleteAddress,
      goToEditAddress
    }
  }
}
</script>

<style lang="scss" scoped>

.address-card-wrapper {
  border: 1px solid #C3C3C3;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--white-color);
  padding: 12px 16px;
  position: relative;
  &.is-selected {
    border: 2px solid var(--primary-color);
  }
}

.address-name {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 1.2rem;
}

.main-address {
  margin-top: 8px;
  color: var(--success-color);
}

.bottom-wrapper {
  display: flex;
  align-items: center;
}

.remove {
  margin-left: auto;
}

.check-wrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);
}
</style>
