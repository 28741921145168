import axios from 'axios';

const patrickApi = axios.create({
  baseURL: process.env.VUE_APP_PATRICK,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const getVisit = async (customerId) => {
  try {
    const response = await patrickApi.get(`/mgmt/visit/sync/${customerId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to retrieve visit:', error);
    throw error;
  }
};


export default {
  getVisit,
};
