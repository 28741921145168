<template>
  <div class="wrapper">
    <img class="product-img" :src="thumbnail" alt="product" />
    <img class="tag" v-if="tag" src="@/assets/images/tag-example.svg" alt="tag" />
    <div v-if="status === 'new'" class="new">NEW</div>
    <div v-else-if="status === 'hot'" class="hot">HOT</div>
    <p class="title">{{ title }}</p>
    <div class="price-and-sold" v-if="!isLaser">
      <p class="price">฿{{ price }}</p>
      <p class="sold">{{ t('soldAmount', [ sold ])}}</p>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
  props: {
    title: {
      type: String,
      require: true,
    },
    price: {
      type: String,
      require: true,
    },
    sold: {
      type: [ Number, String ],
      require: true,
    },
    tag: {
      type: String,
    },
    status: {
      type: String,
      require: true,
    },
    thumbnail: {
      type: String,
      default: ''
    },
    isLaser: {
      type: Boolean,
       default: false,
    }
  },
  setup() {
    /** context */
    const { t } = useI18n()

    return {
      t,
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  transition: 0.1s;
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}

.product-img {
  width: 100%;
  min-height: 136px;
  max-height: 180px;
  border-radius: 6px;
  object-fit: contain;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.price-and-sold {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.price {
  font-size: 14px;
  font-weight: bold;
  color: #FD6868;
}

.sold {
  font-size: 12px;
  color: #6A6A6A;
}

.tag {
  position: absolute;
  top: 0;
  right: 5%;
}

.new {
  background-color: #ffe209;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 2%;
}

.hot {
  background-color: #fa4040;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 10px;
  position: absolute;
  top: 2%;
  left: 2%;
  color: var(--white-color);
}
</style>
