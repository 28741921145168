<template>
  <Carousel :autoplay="autoplay" :wrap-around="wrapAround" @touchstart="touchStart" @touchmove="preventVerticalScroll">
    <Slide v-for="(item, i) in displayItems" :key="i">
      <!-- <img class="carousel__item" :src="item.img_url" @click="goTo(item)" /> -->
      <img class="carousel__item" :src="item.img_url" @click="goTo(item)" />
    </Slide>
    <template #addons>
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { computed } from 'vue'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'

export default {
  components: {
    Carousel,
    Slide,
    Pagination
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean || Number,
      default: 3000
    },
    wrapAround: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    /** constant */
    const slickOptions = {
      arrows: false,
    }

    /** method */
    const goTo = (item) => {
      if (item.url) {
        window.location.href = item.url
      }
    }

    const displayItems = computed(() => props?.items?.length > 0 ? props.items : [{ img_url: require('@/assets/logo.jpg')}])

    let firstClientX
    const scrollLockThreshold = 100

    const touchStart = e => {
      firstClientX = e.touches[0].clientX
    }

    const preventVerticalScroll = e => {
      const xDiff = Math.abs(e.touches[0].clientX - firstClientX)

      if (xDiff > scrollLockThreshold && e.cancelable) {
        e.preventDefault()
        e.returnValue = false
        return false
      }
    }

    return {
      slickOptions,
      displayItems,
      goTo,
      touchStart,
      preventVerticalScroll,
    }
  }
}
</script>

<style lang="scss">
.carousel .carousel__slide--visible,
.carousel__track,
.carousel__viewport {
-ms-touch-action: pan-x;
touch-action: pan-x;
}
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}

.carousel__item {
  max-height: 390px;
  width: 100%;
  object-fit: contain;
}

.carousel__slide {
  // padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carousel__pagination {
  padding: 0;
  position: absolute;
  bottom: 8px;
  z-index: 8;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__pagination-button {
  width: 8px;
  height: 8px;
  border-radius: 6px;
  background-color: #C4C4C4;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  outline: none;
  &.carousel__pagination-button--active {
    background-color: var(--secondary-color);
  }
}
</style>