<template>
  <div class="wrapper">
    <img :src="url" :alt="title">
    <p class="title">{{ title }}</p>
    <button>
      {{ t('goToStore') }}
    </button>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
}

p {
  margin-left: 16px;
  font-size: 1.2rem;
  font-weight: bold;
}

button {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  outline: none;
  background-color: transparent;
  padding: 8px 16px;
  border-radius: 6px;
  margin-left: auto;
}
</style>
