<template>
  <div class="wrapper">
    <img :src="url" alt="brand" />
    <p class="brand-title">{{ title }}</p>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
}

img {
  width: 40px;
  height: 40px;
  border-radius: 4px;
}

p {
  font-size: 1.2rem;
  margin-left: 16px;
}
</style>
