<template>
  <div class="wrapper">
    <button class="add-line" @click="askLine">
      <img src="@/assets/icons/line.svg" alt="line">
      <p>{{ t('product.ask') }}</p>
    </button>
    <button class="add-to-cart" @click="addToCart" v-if="!isLaser">
      <img src="@/assets/icons/plus.svg" alt="plus">
      <p>{{ t('product.addToCart') }}</p>
    </button>
    <button class="buy" @click="goToReserve" v-if="isLaser">
      <p>{{ t('laserService.reserve') }}</p>
    </button>
    <button class="buy" @click="checkout" v-else>
      <p>{{ t('product.buyNow') }}</p>
    </button>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import liff from '@line/liff'
import router from '@/router'

export default {
  props: {
    isLaser: {
      type: Boolean,
      default: false,
    },
    product: {
      type: Object,
      default: null,
    }
  },
  setup(props) {
    /** context */
    const store = useStore()
    const { t } = useI18n()

    /** method */
    const goToReserve = () => {
      router.push(`/laser-service/${props?.product?.id}/reserve`)
    }
    const addToCart = () => store.dispatch('cart/fetchAddProduct', {
      product_id: props?.product?.id,
      quantity: 1,
      add_from: 'user',
      thumbnail: props?.product?.thumbnail,
      description: props?.product?.description,
      price: props?.product?.price,
      name: props?.product?.name,
    })
    const checkout = () => store.dispatch('cart/fetchAddProductAndCheckout', {
      product_id: props?.product?.id,
      quantity: 1,
      add_from: 'user',
      thumbnail: props?.product?.thumbnail,
      description: props?.product?.description,
      price: props?.product?.price,
      name: props?.product?.name,
    })
    const askLine = () => {
      const isInClient = liff.isInClient()
      const message = `สอบถามสินค้าชิ้นนี้ https://liff.line.me/1656704789-XlgKEJGjredirectTo=/products/${props?.product?.id}`
      if (!isInClient) {
        const os = liff.getOS()
        if (os === 'android' || os === 'ios') {
          window.open(`https://line.me/R/oaMessage/${process.env.VUE_APP_LINE_AT_ID}/?${encodeURIComponent(message)}`, '_blank')
        }
        else if (os === 'web') {
          window.open(process.env.VUE_APP_LINE_URL_OFFICIAL_ACCOUNT, '_blank')
        }
      }
      else if (isInClient) {
        window.open(`https://line.me/R/oaMessage/${process.env.VUE_APP_LINE_AT_ID}/?${encodeURIComponent(message)}`, '_blank')
      }
    }

    return {
      t,
      addToCart,
      checkout,
      askLine,
      goToReserve,
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.08));
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
}

button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
  font-size: 12px;
  padding: 8px 16px;
}

.add-line {
  background-color: var(--white-color);
  color: #333333;
  flex: 1;
}

.add-to-cart {
  background-color: #FE510B;
  color: var(--white-color);
  flex: 1.6;
}

.buy {
  background: linear-gradient(90deg, #21bcb8 0%, var(--secondary-color) 100%);
  color: var(--white-color);
  font-size: 1.2rem;
  flex: 4;
}
</style>
