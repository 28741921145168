import { createStore } from 'vuex'
import vuexPersistedstate from 'vuex-persistedstate'
// import dayjs from 'dayjs'

import cart from './modules/cart'
import user from './modules/user'
import order from './modules/order'
import filter from './modules/filter'
import loading from './modules/loading'
import products from './modules/products'
import snackBar from './modules/snackBar'
import frontpage from './modules/frontpage'
import languages from './modules/languages'

export default createStore({
  modules: {
    cart,
    user,
    order,
    filter,
    loading,
    products,
    snackBar,
    frontpage,
    languages,
  },
  plugins: [
    vuexPersistedstate({
      storage: localStorage,
      key: 'makalin-user',
      paths: [ 'user.data' ]
    })
  ]
})
