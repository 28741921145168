<template>
  <div class="product-in-cart-card-wrapper">
    <img :src="product?.product_thumbnail" alt="product" class="product">
    <div class="detail-wrapper">
      <p class="title">{{ product.product_name }}</p>
      <p class="price">{{ t('order.amount') }} {{ product?.quantity }} {{ t('order.unit') }} / ฿{{ product?.price }}</p>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  props: {
    product: {
      type: Object,
      default: null,
    }
  },
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  }
}
</script>

<style lang="scss" scoped>
.product-in-cart-card-wrapper {
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
}

img.product {
  height: 32px;
  width: 32px;
  border-radius: 6px;
}

.detail-wrapper {
  margin-left: 12px;
}

.title {
  font-size: 20px;
}

.price {
  font-size: 16px;
  color: #333333;
}

</style>
