import { ref, watch } from 'vue'

export default (startVal, validators, onValidate) => {
  const input = ref(startVal)
  const errors = ref([])
  watch(input, value => {
    errors.value = validators.reduce((errs, validator) => {
      const err = validator ? validator(value) : null
      if (err) {
        return [
          ...errs,
          err
        ]
      }
      return errs
    }, [])
    onValidate(value)
  })

  return {
    input,
    errors,
  }
}
