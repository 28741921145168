<template>
  <div class="wrapper">
    <div class="menu-wrapper">
      <div
        v-for="(m, i) in menu" :key="i"
        :class="`menu${currentIndex === i ? ' active' : ''}`"
        @click="onClickMenu(m, i)"
      >
        {{ m }}
      </div>
    </div>
    <div :class="`all-detail${(show || isLaserProduct) ? ' show' : ''}`">
      <slot />
    </div>
    <button class="more-detail" @click="show = !show" v-if="!isLaserProduct">
      {{ show ? t('hide') : t('moreDetail') }}
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    isLaser: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const { t } = useI18n()
    /** reactive */
    const currentIndex = ref(0)
    const menu = ref([])
    const show = ref(false)

    /** mounted */
    onMounted(() => {
      document.getElementsByTagName('h1')
      document.querySelectorAll('h1').forEach(el=> {
        el.id = el.outerText
      })
      menu.value = Array.from(document.getElementsByTagName('h1')).map(e => e.outerText)
    })

    /** method */
    const onClickMenu = (id, i) => {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
      currentIndex.value = i
    }

    return {
      t,
      currentIndex,
      menu,
      show,
      onClickMenu,
      isLaserProduct: props.isLaser,
    }
  }
}
</script>

<style lang="scss" scoped>

.wrapper {
  display: flex;
  flex-direction: column;
}

.menu-wrapper {
  display: flex;
  overflow-x: scroll;
  margin: 0 -16px;
  padding: 0 16px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.menu {
  margin: 8px 12px;
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
  color: #8D8D8D;
  padding-bottom: 8px;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &.active {
    color: #21bcb8;
    border-bottom: 1px solid #21bcb8;
  }
}

.more-detail {
  border: 0;
  background-color: transparent;
  color: var(--secondary-color);
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  margin-top: 16px;
  &:active {
    opacity: 0.8;
  }
}

.all-detail {
  max-height: 200px;
  overflow: hidden;
  &.show {
    max-height: none;
  }
}
</style>
