<template>
  <div class="dropdown-wrapper" @click="toggleDropdown">
    <label for="label">{{ label }}</label>
    <div :class="`dropdown-input${show ? ' active' : ''}`">
      {{ selectedLabel }}
      <img class="next-icon" src="@/assets/icons/next.svg" alt="next">
    </div>
    <div v-show="show" class="dropdown-items-wrapper">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="`dropdown-item${item?.value === modelValue?.value ? ' active' : ''}`" 
        @click="onItemSelect(item)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'

export default {
  props: {
    type: {
      default: 'text',
    },
    modelValue: {
      require: true,
    },
    label:{
      default: ''
    },
    name: {
      require: true,
    },
    items: {
      require: true,
      type: Array,
    },
  },
  setup(props, { emit }) {
    /** reactive */
    const show = ref(false)

    /** computed */
    const selectedLabel = computed(() => {
      const found = props.items.find(item => item.value === props?.modelValue?.value)
      if (found) {
        return found.label
      }

      return null
    })

    /** method */
    const toggleDropdown = () => {
      show.value = !show.value
    }
    const onItemSelect = (item) => {
      emit('update:modelValue', item)
    }

    return {
      show,
      selectedLabel,
      onItemSelect,
      toggleDropdown,
    }
  }
}
</script>

<style lang="scss" scoped>

.dropdown-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  position: relative;
}

label {
  color: #6A6A6A;
  margin-bottom: 12px;
}

.dropdown-input {
  border: 1px solid #DFDFDF;
  background-color: #fff;
  padding: 8px 12px;
  border-radius: 8px;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  &.active {
    border: 1px solid var(--secondary-color);
  }
}

.dropdown-items-wrapper {
  top: calc(100% + 4px);
  position: absolute;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow: scroll;
  z-index: 1000;
  border: 1px solid var(--secondary-color);
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

.dropdown-item {
  background-color: var(--white-color);
  border-top: 1px solid var(--secondary-color);
  border-bottom: 1px solid var(--secondary-color);
  cursor: pointer;
  padding: 8px 12px;
  &.active {
    background-color: var(--secondary-color);
    color: var(--white-color);
  }
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:not(:last-child) {
    border-bottom: 0;
  }
}

.next-icon {
  margin-left: auto;
}
</style>
