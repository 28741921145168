<template>
  <div class="select-coupon-wrapper">
    <img src="@/assets/images/coupon.png" alt="coupon">
    <p class="title" @click="goToCoupon">{{ t('myCoupon.useCoupon') }}</p>
    <div v-if="discountInfo" class="go-to-select-coupon">
      <p @click="goToCoupon">
        {{ discountInfo }}
      </p>
      <img src="@/assets/icons/remove.svg" alt="remove" @click="removeCoupon">
    </div>
    <div v-else class="go-to-select-coupon" @click="goToCoupon">{{ t('myCoupon.selectCoupon') }} ></div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** computed */
    const discountInfo = computed(() => store.state.cart.data?.cart_calculated?.discount_info)

    /** method */
    const goToCoupon = () => router.push('/my-cart/coupon')
    const removeCoupon = () => store.dispatch('cart/fetchRemoveCoupon')

    return {
      t,
      discountInfo,
      goToCoupon,
      removeCoupon,
    }
  }
}
</script>

<style lang="scss" scoped>
.select-coupon-wrapper {
  border: 1px solid var(--success-color);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  align-items: center;
  &:active {
    opacity: 0.7;
  }
}

.title {
  color: var(--success-color);
  font-size: 24px;
  margin-left: 8px;
  cursor: pointer;
}

.go-to-select-coupon {
  color: #878787;
  margin-left: auto;
  font-size: 20px;
  display: flex;
  cursor: pointer;
  > p {
    margin-right: 8px;
  }
}
</style>
