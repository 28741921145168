<template>
  <div class="my-coin">
    <img src="@/assets/icons/m-coin.svg" alt="m-coin">
    <div class="description-wrapper">
      <p v-if="user" class="coin">{{ numberWithCommas(user?.coin) || 0 }} Coins</p>
      <p class="more-detail">{{ t('collectCoin') }}</p>
    </div>
    <div class="go" v-if="user && (!user?.is_verified_phone || !user?.makalin_user_id)" @click="goToVerify">
      <span class="go-to-verify">{{ t('goToVerify') }}</span>
      <img src="@/assets/icons/grey-forward.svg" alt="grey-forward">
    </div>
    <div class="go" v-else-if="!user" @click="goToLoginWithLine">
      <span class="go-to-verify">{{ t('goToVerify') }}</span>
      <img src="@/assets/icons/grey-forward.svg" alt="grey-forward">
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import numberWithCommas from '@/utils/numberWithCommas'

export default {
  setup() {
    /** context */
    const store = useStore()
    const { t } = useI18n()
    const router = useRouter()

    /** computed */
    const user = computed(() => store.getters['user/getUserProfile'])
    
    const goToVerify = () => {
      store.commit('user/SET_SHOW_VERIFY', false)
      router.push('/user/verify')
    }

    const goToLoginWithLine = () => {
      window.location.href = `https://liff.line.me/${process.env.VUE_APP_LINE_LIFE_ID}?pv=1`
    }

    return {
      t,
      user,
      goToVerify,
      numberWithCommas,
      goToLoginWithLine,
    }
  }
}
</script>

<style lang="scss" scoped>

.my-coin {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 12px 16px;
  background-color: var(--white-color);
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}

.description-wrapper {
  flex: 1;
  padding-left: 12px;
}

.coin {
  font-weight: bold;
  font-size: 1.2rem;
}

.go {
  font-size: 12px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
}

.go-to-verify {
  margin-right: 12px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--secondary-color);
}
</style>
