<template>
  <div class="cancellation-container">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('cancellation.title') }}</p>
    </div>
    <div class="content-wrapper">
      <p class="description mb-2">{{ t('cancellation.description') }}</p>
      <Radio
        v-for="(reasonItem, index) in reasonList"
        :key="index"
        v-model="selectedReason"
        :label="reasonItem.reason"
        :value="reasonItem.reason"
        class="mb-2 ml-2"
      />
      <MButton @click="submit" class="mt-a">
        {{ t('submit') }}
      </MButton>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import Radio from '@/components/Form/Radio'
import MButton from '@/components/Form/MButton'

export default {
  name: 'cancellationView',
  components: {
    Radio,
    MButton,
  },
  setup() {
    /** context */
    const store = useStore()
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const id = route.params.id
    /** reactive */
    const selectedReason = ref(null)

    /** computed */
    const reasonList = computed(() => store.state.order?.cancelReasonList)

    /** method */
    const back = () => router.back()
    const submit = () => {
      store.dispatch('order/fetchCancelOrder', { id, reason: selectedReason.value })
    }

    /** mounted */
    onMounted(() => {
      store.dispatch('order/fetchGetCancelReasonList')
    })

    return {
      t,
      back,
      submit,
      reasonList,
      selectedReason,
    }
  }
}
</script>

<style lang="scss" scoped>

.cancellation-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 56px;
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  padding: 12px;
  height: 100%;
}

.description {
  font-size: 22px;
}

</style>
