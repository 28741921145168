import api from '@/api'

const getFrontpageData = async () => {
  const result = await api.get('/api/core/frontpage?format=json', {
    noRequiredToken: true
  })
  let categories = []
  result.categories.forEach(element => {
    if (element.id <10) {
      element.ordering = element.id + 1
    }
    else if (element.id == 10) {
      element.ordering = 1
    }
    else {
      element.ordering = element.id
    }

    categories.push(element)
  });
  categories.sort((a, b) => a.ordering - b.ordering)
  result.categories = categories
  return result
}

const getAboutUs = async () => {
  const result = await api.get('/api/core/about_us?format=json', {
    noRequiredToken: true
  })
  
  return result
}

export default {
  getFrontpageData,
  getAboutUs,
}
