import api from '@/api'

const post3dSecured = async (gbpReferenceNo) => {
  const params = new URLSearchParams()
  params.append('gbpReferenceNo', gbpReferenceNo)
  params.append('publicKey', process.env.VUE_APP_GBPAY_PUBLIC_KEY)
  const result = await api.post(`/v2/tokens/3d_secured`, params, {
    noRequiredToken: true,
    noRequireLanguage: true,
    baseURL: process.env.VUE_APP_GBPAY_API_BASE_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },)
  
  return result
}

const getGbPayToken = async (body) => {
  const result = await api.post(`/v2/tokens`, body, {
    baseURL: process.env.VUE_APP_GBPAY_API_BASE_URL,
    noRequiredToken: true,
    noRequireLanguage: true,
    headers: {
      Authorization: `Basic ${btoa(`${process.env.VUE_APP_GBPAY_PUBLIC_KEY}:`)}`
    }
  })

  return result
}
export default {
  post3dSecured,
  getGbPayToken
}
