<template>
  <div class="coupon-container">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('myCoupon.title') }}</p>
    </div>
    <div v-if="coupons.length === 0" class="no-coupon-wrapper">
      <img src="@/assets/images/no-coupon.svg" alt="no-coupon">
      <p class="no-coupon-text">{{ t('myCoupon.noItem') }}</p>
    </div>
    <div v-else-if="coupons.length > 0" class="coupon-wrapper">
      <CouponCard
        v-for="coupon in coupons"
        :key="coupon.id"
        :coupon="coupon"
      />
      <MButton :disabled="!selectedCoupon" class="abs-b" @click="back">{{  t('submit') }}</MButton>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import CouponCard from '@/components/CouponCard'
import MButton from '@/components/Form/MButton'

export default {
  name: 'CouponView',
  components: {
    CouponCard,
    MButton,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    /** computed */
    const coupons = computed(() => store.state.cart.data?.coupon_promotions || [])
    const selectedCoupon = computed(() =>  store.state.cart.data.coupon_code)

    /** method */
    const back = () => router.back()

    return {
      t,
      back,
      coupons,
      selectedCoupon
    }
  }
}
</script>

<style lang="scss" scoped>
.coupon-container {
  display: flex;
  flex-direction: column;
  padding-top: 56px;
}

.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.coupon-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 16px;
  padding-bottom: 64px;
  gap: 16px;
}

.no-coupon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.no-coupon-text {
  font-size: 16px;
  margin: 32px 0;
}

.abs-b {
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 12px;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 700px;
  }
}

</style>
