<template>
  <div class="wrapper">
    <img class="product-img" :src="item.thumbnail" alt="product" />
    <p class="title">{{ item.name }}</p>
    <div class="price-and-sold">
      <p class="price">฿{{ item.price }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  transition: 0.1s;
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}

.product-img {
  width: 74px;
  height: 74px;
  border-radius: 6px;
  object-fit: cover;
}

.title {
  font-size: 20px;
  line-height: 1.5em;
  height: 4.5em;
  overflow: hidden;
}

.price-and-sold {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.price {
  font-size: 20px;
  font-weight: bold;
  color: #FD6868;
}
</style>
