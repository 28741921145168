import frontpageApi from '@/api/frontpage'
import storeApi from '@/api/store'
import parseArrayToObject from '@/utils/parseArrayToObject'

export default {
  namespaced: true,
  state: {
    data: {
      aboutUs: '',
      banners: [
        {
          id: 1,
          name: '3.3',
          img_url: require('@/assets/banner.jpg'),
          url: process.env.VUE_APP_BASE_URL,
          ordering: 1
        },
        {
          id: 1,
          name: '3.3',
          img_url: require('@/assets/banner.jpg'),
          url: process.env.VUE_APP_BASE_URL,
          ordering: 1
        },
        {
          id: 1,
          name: '3.3',
          img_url: require('@/assets/banner.jpg'),
          url: process.env.VUE_APP_BASE_URL,
          ordering: 1
        },
      ],
      categories: null,
      recentProducts: null,
      recommendProducts: null,
    },
  },
  getters: {},
  actions: {
    async fetchFrontpageData({ state, commit }) {
      let banners = []
      const response = await frontpageApi.getFrontpageData()
      try {
        banners = await storeApi.getBanners()
      }
      catch (err) {
        console.log(err)
      }
      commit('SET_FRONTPAGE', {
        banners: banners.length > 0 ? banners : state.data.banners.sort((a, b) => a.ordering - b.ordering),
        categories: parseArrayToObject(response.categories, 'ordering'),
        recentProducts: parseArrayToObject(response.recently_products, 'id'),
        recommendProducts: parseArrayToObject(response.recommend_products, 'id'),
        latestReceiveProducts: parseArrayToObject(response.recommend_products, 'id'),
      })
    },
    async fetchAboutUs({ commit }) {
      const response = await frontpageApi.getAboutUs()
      commit('SET_ABOUT_US', response?.about_us)
    }
  },
  mutations: {
    SET_FRONTPAGE(state, data) {
      state.data = data
    },
    SET_ABOUT_US(state, data) {
      state.aboutUs = data
    },
  },
}
