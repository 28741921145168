<script>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import BackTopBar from '@/components/BackTopBar'
import LaserCard from '@/components/LaserCard'

export default {
  name: 'laserService',
  components: {
    BackTopBar,
    LaserCard,
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()

    const goToLaserProduct = (laser) => {
      router.push(`/laser-service/${laser.id}`)
    }

    return {
      t,
      goToLaserProduct,
    }
  }
}
</script>

<template>
  <div class="container">
    <BackTopBar />
    <p class="title mb-2 mt-2">{{ t('laserService.title') }}</p>
    <p class="description mb-2">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nulla doloribus debitis voluptas deleniti nostrum saepe voluptates tempora ut blanditiis adipisci aperiam, amet possimus. Veritatis adipisci, hic nisi incidunt obcaecati quibusdam.</p>
    <div class="laser-products-wrapper">
      <LaserCard
        title="เลเซอร์หน้าใส (Skin Laser)"
          @click="goToLaserProduct({ id: 34 })"
      />
      <LaserCard
        title="เลเซอร์หน้าใส (Skin Laser)"
      />
      <LaserCard
        title="เลเซอร์หน้าใส (Skin Laser)"
      />
      <LaserCard
        title="เลเซอร์หน้าใส (Skin Laser)"
      />
      <LaserCard
        title="เลเซอร์หน้าใส (Skin Laser)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>

.container {
  padding: 16px;
  padding-top: 48px;
  padding-bottom: 60px;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.description {
  font-size: 18px;
  color: #878787;
}

.laser-products-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
}
</style>
