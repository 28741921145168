import api from '@/api'

const getOrders = async () => {
  const result = await api.get('/api/order/my_order')
  
  return result
}

const getOrderById = async (orderId) => {
  const result = await api.get(`/api/order/${orderId}`)
  
  return result
}

const getOrderStatus = async (orderId) => {

  let result = null
  try{
    let address = JSON.parse(localStorage.getItem('userAddress'))
    result = await api.post('/api/order/ping_payment_status', { order_id: orderId, user: address[0].phone })

  }catch(err){
    result = await api.post('/api/order/ping_payment_status', { order_id: orderId, user: "" })
  }
  
  return result
}

const saveLog = async (request) => {
  const result = await api.post(`/api/order/save_log`, request, { loading: false })
  return result
}


const getPaymentDetailById = async (orderId) => {
  let result = null
  try{
    let address = JSON.parse(localStorage.getItem('userAddress'))
    result = await api.post('/api/order/payment_detail', { order_id: orderId, user: address[0].phone })
  }catch(err){
    result = await api.post('/api/order/payment_detail', { order_id: orderId, user: "" })
  }
  
  return result
}

const downloadQrCode = async (orderId) => {
  const result = await api.get(`/api/order/download_qrcode/${orderId}`, { responseType: "arraybuffer" })
  
  return result
}

const getCancelReasonList = async () => {
  const result = await api.get('/api/order/cancellation_reason_list')

  return result
}

const cancelOrder = async (orderId, reason) => {
  const result = await api.post('/api/order/cancel_order', {
    order_id: orderId,
    reasons: [
      reason
    ]
  })

  return result
}

export default {
  getOrders,
  cancelOrder,
  getOrderById,
  downloadQrCode,
  getOrderStatus,
  getCancelReasonList,
  getPaymentDetailById,
  saveLog
}
