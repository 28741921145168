<template>
  <div class="wrapper">
    <p class="price">฿{{ price }}</p>
    <p v-if="discount !== '0.00'" class="discount">฿{{ discount }}</p>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: String,
      require: true,
    },
    discount: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
}

.price {
  font-size: 1.3rem;
  font-weight: bold;
  color: #FD6868;
}

.discount {
  font-size: 1.2rem;
  color: #C4C4C4;
  text-decoration: line-through;
  margin-left: 8px;
}
</style>
