<template>
  <div class="wrapper">
    <button @click="goBack">
      <img src="@/assets/icons/primary-back.svg" alt="back" />
    </button>
    <button @click="goToAboutUs" class="ml-a">
      <img src="@/assets/icons/info.svg" alt="info" />
    </button>
    <button @click="goToCart" v-if="!isLaser">
      <img src="@/assets/icons/primary-basket.svg" alt="basket" />
      <span class="amount" v-if="totalItemAmount > 0">
        {{ numberWithCommas(totalItemAmount) }}
      </span>
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import numberWithCommas from '@/utils/numberWithCommas'

export default {
  props: {
    isLaser: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    /** computed */
    const totalItemAmount = computed(() => store.getters['cart/totalItemAmount'])

    /** method */
    const goBack = () => {
      if (route.query.b) {
        router.back()
      }
      else {
        router.replace('/')
      }
    }
    const goToCart = () => {
      router.push('/my-cart')
    }
    const goToAboutUs = () => {
      router.push('/about-us')
    }

    return {
      goBack,
      goToCart,
      goToAboutUs,
      totalItemAmount,
      numberWithCommas,
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  z-index: 8;
  left: 0;
  right: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
}

button {
  border: 0;
  outline: none;
  background-color: transparent;
  transition: 0.1s;
  position: relative;
  padding: 12px;
  &:active {
    transform: scale(0.8);
  }
}

.amount {
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  transform: translate(calc(50% - 12px), calc(0% + 12px));
  width: 18px;
  height: 18px;
  background-color: var(--error-color);
  color: var(--white-color);
}
</style>
