<template>
  <div class="product-in-cart-card-wrapper">
    <img :src="item?.product?.thumbnail" alt="product" class="product">
    <div class="detail-wrapper">
      <p class="title">{{ item?.product.name }}</p>
      <p class="price">฿{{ item?.product.price }}</p>
      <div class="amount-control-wrapper">
        <button @click="substract">-</button>
        <p class="amount">{{ amount }}</p>
        <button @click="add">+</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    getUser: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    /** context */
    const store = useStore()
    const getUser = computed(() => store.getters['user/getUser'])

    /** reactive */
    const amount = ref(props.item?.amount)

    /** method */
    const add = () => {
      amount.value++
      let data = {
        "product_id": props.item?.product?.id,
        "product_name": props.item?.product?.name,
        "user": getUser.value
      }
      store.dispatch('cart/fetchIncreaseProduct', data)
    }
    const substract = () => {
      amount.value--
      let data = {
        "product_id": props.item?.product?.id,
        "product_name": props.item?.product?.name,
        "user": getUser.value
      }
      store.dispatch('cart/fetchDecreaseProduct', data)
    }

    return {
      amount,
      add,
      substract
    }
  }
}
</script>

<style lang="scss" scoped>
.product-in-cart-card-wrapper {
  border: 1px solid #C3C3C3;
  padding: 8px 12px;
  border-radius: 6px;
  display: flex;
}

img.product {
  height: 72px;
  width: 72px;
  border-radius: 6px;
}

.detail-wrapper {
  margin-left: 12px;
}

.title {
  font-size: 20px;
}

.price {
  color: var(--error-color);
  font-size: 20px;
  font-weight: bold;
}

.amount-control-wrapper {
  margin: 4px 30px 0;
  display: flex;
  align-items: center;
  > button {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #C3C3C3;
    border-radius: 4px;
    color: #004890;
    outline: none;
    background-color: #fff;
    &:active {
      opacity: 0.7;
    }
  }

  .amount {
    font-size: 20px;
    margin: 0 16px;
  }
}
</style>
