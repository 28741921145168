import axios from 'axios';

// Set up a different base URL for the referral-related API
const referralApi = axios.create({
  baseURL: process.env.VUE_APP_MAKALIN_REFERRAL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function to get user rewards based on referrer_id
export const getUserRewards = async (referrerId) => {
  try {
    const response = await referralApi.get(`/api/user_rewards/?referrer_id=${referrerId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to retrieve user rewards:', error);
    throw error;
  }
};

export const getUserOwnerRewards = async (userId) => {
  try {
    const response = await referralApi.get(`/api/user_rewards/?user_id=${userId}`);
    // for (let i = 0; i < response.data.length; i++) {
    //   if (response.data[i].type == "QR_CODE") {
    //     response.data.splice(i, 1);
    //   }

    // }
    return response.data;
  } catch (error) {
    console.error('Failed to retrieve user rewards:', error);
    throw error;
  }
};

// Function to post user rewards
export const postUserReward = async ({ userId, rewardId, dateClaim, type, referrerId, status, order_id }) => {
  try {
    const payload = {
      user_id: userId,
      reward_id: rewardId,
      date_claim: dateClaim,
      type: type,
      referrer_id: referrerId,
      status: status,
      order_id: order_id
    };
    const response = await referralApi.post('/api/user_rewards/', payload);
    return response.data;
  } catch (error) {
    console.error('Failed to post user reward:', error);
    throw error;
  }
};

export default {
  getUserRewards,
  postUserReward,
  getUserOwnerRewards
};
