<template>
  <div class="redirect-container">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
        <p>{{ t('back') }}</p>
      </button>
    </div>
    <p class="redirect-text">
      {{ redirectMessage }}
    </p>
    <br />
    <p class="go-to-payment" @click="goToPayment" v-show="show">
      {{ t('clickToPayment') }}
    </p>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  name: 'RedirectView',
  setup() {

    const show = ref(false)
    /** context */
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const { redirectUrl, redirectMessage, id } = route.query

    const goToPayment = () => {
      if (redirectUrl) {
        window.location.href = redirectUrl
      }
      else {
        alert (t('somethingWrong'))
        router.replace(`/my-orders/${id}`)
      }
    }

    const back = () => {
      router.replace(`/my-orders/${id}`)
    }

    onMounted(() => {
      if (redirectUrl) {
        setTimeout(() => {
          goToPayment()
          show.value = true
        }, 2000)
      }
    })

    return {
      t,
      redirectMessage,
      redirectUrl,
      goToPayment,
      back,
      show,
    }
  },
}
</script>

<style lang="scss" scoped>

.redirect-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.redirect-text {
  font-size: 24px;
  font-weight: bold;
  color: var(--secondary-color);
}

.go-to-payment {
  text-decoration: underline;
  color: rgb(3, 59, 190);
  font-size: 16px;
  cursor: pointer;
}

.top-header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
  background-color: var(--white-color);
  > button {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    > img {
      margin-right: 12px;
    }
  }
}
</style>
