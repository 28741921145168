<template>
  <div class="checkbox-wrapper" @click="onClick">
    <div class="check-box" :class="{ 'is-checked': modelValue }">
      <div class="inside">
        <img src="@/assets/icons/small-check.svg" alt="check">
      </div>
    </div>
    <div class="label-wrapper">
      <div class="label">
        {{ label }}
        <span v-if="required">*</span>
      </div>
      <div class="description">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    /** method */
    const onClick = () => {
      emit('update:modelValue', !props.modelValue)
    }

    return {
      onClick,
    }
  }
}
</script>

<style lang="scss" scoped>

.checkbox-wrapper {
  display: flex;
  align-items: center;
}
.check-box {
  cursor: pointer;
  margin: 8px;
  min-width: 16px;
  height: 16px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  .inside {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    background-color: var(--primary-color);
    transition: all 0.2s ease;
    > img {
    }
  }
  &.is-checked {
    > .inside {
      transform: scale(1);
    }
  }
}

.label-wrapper {
  margin-left: 8px;
  .label {
    font-size: 1.2rem;
    font-weight: 100;
    > span {
      color: red;
    }
  }
  .description {
    font-size: 0.9rem;
  }
}
</style>
