<template>
  <div class="continue-drug-modal-wrapper">
    <div class="top-header-wrapper">
      <button @click="deselectDrug">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('myContinueDrugs.howToUse') }}</p>
    </div>
    <div class="content-wrapper" ref="contentRef">
      <div class="product-wrapper">
        <img
          v-if="item.thumbnail"
          class="product-img"
          :src="item.thumbnail"
          alt="product-img"
        />
        <div class="product-description">
          <p class="name mb-1">{{ item.name }}</p>
          <p class="description">{{ item.description }}</p>
        </div>
      </div>
      <video
        autoplay
        :width="deviceWidth"
        v-if="item.video"
        :src="item.video"
        controls="true"
      >
      </video>
      <div class="detail-wrapper">
        <div class="row">
          <p class="detail-title">{{ t('myContinueDrugs.type') }}</p>
          <p class="detail-description">{{ item.drug_type }}</p>
        </div>
        <div class="row">
          <p class="detail-title">{{ t('myContinueDrugs.unit') }}</p>
          <p class="detail-description">{{ item.weight }} {{ item.unit ? item.unit : 'g.' }}</p>
        </div>
      </div>
      <MButton class="mt-a " fullWidth @click="goToProduct(item)">{{ t('myContinueDrugs.seeProduct') }}</MButton>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import MButton from './Form/MButton'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    deselectDrug: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    MButton,
  },
  setup() {
    const router = useRouter()
    const { t } = useI18n()

    const deviceWidth = computed(() => {
      if (window.innerWidth > 500) return 500

      return window.innerWidth - 32
    })

    const goToProduct = (product) => {
      router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)
    }
    return {
      t,
      goToProduct,
      deviceWidth,
    }
  }
}
</script>

<style lang="scss" scoped>

.continue-drug-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  overflow-y: scroll;
  padding-top: 56px;
}


.top-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-wrapper {
  display: flex;
  padding-bottom: 16px;
  border-bottom: 1px solid #c3c3c3;
  margin-bottom: 16px;
}

.product-img {
  width: 25%;
  border-radius: 6px;
  &:active {
    opacity: 0.7;
  }
}

.product-description {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  > .name {
    font-size: 2rem;
    font-weight: bold;
  }
  > .description {
    font-size: 1.5rem;
  }
}

.video-tag {
  object-fit: fill;
  margin: 0 auto;
}

.detail-wrapper {
  padding: 16px;
  margin: 16px 0;
  border: 1px solid #c3c3c3;
  border-radius: 6px;
  > .row {
    display: flex;
    .detail-title {
      font-weight: bold;
    }
    > p {
      flex: 1;
      font-size: 1.25rem;
    }
  }
}
</style>