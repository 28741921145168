<template>
  <div
    class="snack-bar-wrapper"
    :class="{
      'is-active': !!message,
      'warning': color === 'warning',
      'error': color === 'error',
      'success': color === 'success',
    }"
  >
    {{ message }}
    <span class="close" @click="closeSnackBar">✖</span>
  </div>
</template>

<script>
import { computed, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'

export default {
  setup() {
    /** context */
    const store = useStore()

    /** reactive */
    const timeout = ref(null)

    /** computed */
    const message = computed(() => store.state.snackBar.data)
    const color = computed(() => store.state.snackBar.color)

    /** method */
    const closeSnackBar = () => store.commit('snackBar/RESET_DATA')

    /** watch */
    watchEffect(() => {
      if (message.value) {
        timeout.value = setTimeout(() => {
          closeSnackBar()
        }, 5000)
      }
      else {
        if (timeout.value) clearTimeout(timeout.value)
      }
    })
    
    return {
      message,
      closeSnackBar,
      color,
    }
  }
}
</script>

<style lang="scss" scoped>
.snack-bar-wrapper {
  position: fixed;
  z-index: 9;
  top: 0;
  width: 80%;
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
  @media screen and (min-width: 780px) {
    width: 700px;
  }
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  > .close {
    margin-left: auto;
    transform: translate(4px, -4px);
  }
  &.is-active {
    transform: translateY(12px) translateX(-50%);
  }
  &.warning {
    background-color: var(--secondary-warning-color);
    border: 1px solid var(--warning-color);
    color: var(--warning-color);
  }
  &.error {
    background-color: var(--secondary-error-color);
    border: 1px solid var(--error-color);
    color: var(--error-color);
  }
  &.success {
    background-color: var(--secondary-success-color);
    border: 1px solid var(--success-color);
    color: var(--success-color);
  }
}
</style>
