export default {
  namespaced: true,
  state: {
    data: null,
    color: 'warning'
  },
  mutations: {
    SET_DATA(state, { message, color = 'warning' }) {
      state.data = message
      state.color = color
    },
    RESET_DATA(state) {
      state.data = null
    }
  },
}
