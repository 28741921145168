<template>
  <div class="input-wrapper">
    <img src="@/assets/icons/search.svg" alt="search">
    <input type="text" :placeholder="t('search')" :value="modelValue" @input="onChange" autofocus>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  props: {
    modelValue: {
      require: true,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n()
    /** method */
    const onChange = (event) => {
      emit('update:modelValue', event.target.value)
    }

    return {
      t,
      onChange
    }
  }
}
</script>

<style lang="scss" scoped>

.input-wrapper {
  background-color: var(--white-color);
  border: 1px solid var(--secondary-color);
  padding: 8px 20px;
  border-radius: 38px;
  flex: 1;
  display: flex;
}

input {
  border: 0;
  outline: none;
  width: 100%;
  margin-left: 8px;
  &::-webkit-input-placeholder {
    color: #CCCCCC;
  }

  &:-ms-input-placeholder {
    color: #CCCCCC;
  }

  &::placeholder {
    color: #CCCCCC;
  }
}
</style>
