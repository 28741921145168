<template>
  <div class="back-top-bar">
    <div class="back-wrapper" @click="goBack">
      <img src="@/assets/icons/primary-back.svg" alt="back" class="back">
      <p class="back">
        {{ t('back') }}
      </p>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    /** context */
    const router = useRouter()
    const { t } = useI18n()

    /** method */
    const goBack = () => router.back()

    return {
      t,
      goBack,
    }
  }
}
</script>

<style lang="scss" scoped>

.back-top-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  padding: 8px 0;
  display: flex;
}

.back-wrapper {
  display: flex;
  padding: 8px;
  cursor: pointer;
}

.back {
  color: var(--secondary-color);
  font-size: 1.2rem;
  margin-left: 12px;
}
</style>
