<template>
  <div class="latest-receive-container">
    <BackTopBar />
    <p class="title mb-2">{{ t('myDiaryDrugs.continueDrugsTitle') }}</p>
    <div class="select-all ml-a" @click="toggleSelectAllProduct" v-if="fequencyDrugs?.length > 0">
      <div class="check-box all" :class="{ 'is-checked': selectedProducts.length === continueDrugs?.length }">
        <div class="inside">
          <img src="@/assets/icons/small-check.svg" alt="check">
        </div>
      </div>
      <p class="ml-1" v-if="selectedProducts.length !== continueDrugs?.length">
        {{ t('myDiaryDrugs.selectAll') }}
      </p>
      <p class="ml-1" v-else>
        {{ t('myDiaryDrugs.removeAll') }}
      </p>
    </div>
    <div v-if="continueDrugs.length > 0" class="products-wrapper">
      <div
        class="select-card-wrapper"
        v-for="(product, index) in continueDrugs"
        :key="index"
      >
        <div class="check-box" :class="{ 'is-checked': selectedProducts.includes(product.id) }" @click="toggleSelectProduct(product)">
          <div class="inside">
            <img src="@/assets/icons/small-check.svg" alt="check">
          </div>
        </div>
        <ProductCard
          :title="product.name"
          :price="numberWithCommas(product.price)"
          :sold="numberWithCommas(product.sold)"
          :thumbnail="product.thumbnail"
          @click="goToProduct(product)"
        />
      </div>
    </div>
    <div class="no-item" v-else>
      <!-- <img src="@/assets/images/cart.svg" alt="cart"> -->
      <p class="no-item-text">
        {{ t('myDiaryDrugs.noItem') }}
      </p>
    </div>
    <div class="button-wrapper">
      <MButton fullWidth @click="addProductToCart" v-if="continueDrugs?.length > 0">
        {{ t('myDiaryDrugs.addSelect') }}
      </MButton>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import numberWithCommas from '@/utils/numberWithCommas'

import BackTopBar from '@/components/BackTopBar'
import ProductCard from '@/components/ProductCard'
import MButton from '@/components/Form/MButton'

export default {
  components: {
    BackTopBar,
    ProductCard,
    MButton,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()

    const selectedProducts = ref([])
    /** computed */
    const continueDrugs = computed(() => store.getters['user/getMyContinueDrugs'])

    /** method */
    const toggleSelectAllProduct = () => {
      if (selectedProducts.value.length === continueDrugs.value?.length) {
        selectedProducts.value = []
      }
      else {
        selectedProducts.value = continueDrugs.value.map(product => product.id)
      }
    }
    const toggleSelectProduct = (product) => {
      const found = selectedProducts.value.includes(product.id)
      if (found) {
        selectedProducts.value = selectedProducts.value.filter(p => p !== product.id)
      }
      else {
        selectedProducts.value.push(product.id)
      }
    }
    const goToProduct = (product) => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)
    const addProductToCart = async () => {
      await selectedProducts.value.forEach(async id => {
        await store.dispatch('cart/fetchAddProduct', {
          product_id: id,
          quantity: 1,
          add_from: 'user',
        })
      })
    }

    /** mounted */
    onMounted(() => {
      store.dispatch('user/fetchMyContinueDrugs')
    })

    return {
      t,
      continueDrugs,
      goToProduct,
      numberWithCommas,
      selectedProducts,
      toggleSelectProduct,
      addProductToCart,
      toggleSelectAllProduct,
    }
  }
}
</script>

<style lang="scss" scoped>

.latest-receive-container {
  padding: 16px;
  padding-top: 48px;
  padding-bottom: 60px;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.products-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 24px;
}

.button-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  @media screen and (min-width: 780px) {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 780px;
  }
  padding: 16px;
  background: var(--white-color);
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.08);
}

.no-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-item-text {
  font-size: 16px;
  margin: 32px 0;
}

.select-card-wrapper {
  position: relative;
}

.check-box {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 20px;
  overflow: hidden;
  &.all {
    position: static;
    // top: inherit;
    // right: inherit;
  }
  .inside {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    background-color: var(--primary-color);
    transition: all 0.2s ease;
    > img {
    }
  }
  &.is-checked {
    > .inside {
      transform: scale(1);
    }
  }
}

.select-all {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  color: var(--primary-color);
}
</style>
