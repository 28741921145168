<template>
  <button
    class="m-button-wrapper"
    :type="type"
    :class="{
      'full-width': fullWidth,
      'outline': outline,
      'outline-secondary': outlineSecondary,
      'outline-error': outlineError,
      'success': success,
      'ghost': ghost,
      'line': line,
      'disabled': disabled,
      'grey': grey,
      'blue': blue
    }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    outlineSecondary: {
      type: Boolean,
      default: false,
    },
    outlineError: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    line: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    blue: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>

.m-button-wrapper {
  background: linear-gradient(90deg, #21bcb8 0%, var(--secondary-color) 100%);
  border-radius: 8px;
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 1.2rem;
  outline: 0;
  border: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  > p {
    font-size: 1.2rem;
  }
  &:active {
    opacity: 0.7;
  }
  &.full-width {
    width: 100%;
  }
  &.outline {
    background: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
  }

  &.outline-secondary {
    background: transparent;
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
  }
  &.outline-error {
    background: transparent;
    border: 1px solid var(--error-color);
    color: var(--error-color);
  }
  &.success {
    background: var(--success-color);
    border: 1px solid var(--success-color);
    color: #FFFF;
  }
  &.line {
    background: #03C754;
    border: 1px solid #03C754;
    color: #FFFF;
  }
  &.blue {
    background: #4A58E5;
    border: 1px solid #4A58E5;
    color: #FFFF;
  }
  &.ghost {
    background: transparent;
    border: 1px solid transparent;
    color: #363636;
  }
  &.disabled {
    background: #C3C3C3;
    border: 1px solid #C3C3C3;
    color: #FFF;
    pointer-events: not-allowed;
  }
  &.grey {
    border: 1px solid #C3C3C3;
    color: #C3C3C3;
  }
}
</style>
