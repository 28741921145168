<script>
export default {
  props: {
    title: {
      type: String,
      default: '-',
    },
    thumbnail: {
      type: String,
      default: require('@/assets/logo.jpg')
    },
  },
  setup() {
  }
}
</script>

<template>
  <div class="wrapper">
    <img class="product-img" :src="thumbnail" alt="product" />
    <p class="title">{{ title }}</p>
  </div>
</template>

<style lang="scss" scoped>

.wrapper {
  position: relative;
  transition: 0.1s;
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}

.product-img {
  width: 100%;
  min-height: 136px;
  max-height: 180px;
  border-radius: 6px;
  object-fit: contain;
}

.title {
  font-size: 18px;
  font-weight: bold;
}
</style>
