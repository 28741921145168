export default product => {
  let formattedProduct = {
    id: null,
    discount: 0,
    price: 0,
    images: [
      require('@/assets/images/example.png'),
    ],
    tags: [],
    recommendProducts: [],
    name: {
      th: '',
      en: '',
    },
    soldAmount: 0,
    brand: {
      title: '',
      image: '',
    },
    store: {
      title: ''
    },
    description: '',
    isFav: false
  }
  
  if (product) {
    const { images, name, brand, sold, seller, is_favorite, description, tags, price, discount, recommend_products } = product
    formattedProduct = {
      ...product,
      id: product.id,
      discount,
      price,
      images: images?.length > 0 ? images.map(image => image.image) : [ product.thumbnail ],
      tags: images?.length > 0 ? tags.map(tag => tag) : formattedProduct.tags,
      recommendProducts: recommend_products,
      name: {
        th: name,
        en: name,
      },
      soldAmount: sold,
      brand: {
        title: brand?.name,
        image: brand?.image_url,
      },
      store: {
        title: seller?.name
      },
      description,
      isFav: is_favorite
    }
  }

  return formattedProduct
}
