<template>
  <div class="continue-drugs-wrapper">
    <div class="top-wrapper">
      <p class="title">{{ t('myDiaryDrugs.continueDrugsTitle') }}</p>
      <p class="see-all" v-if="continueDrugs?.length > 0" @click="goToContinueDrugs">{{ t('myDiaryDrugs.seeAll') }} ></p>
    </div>
    <MicroProductCard
      v-for="continueDrug in continueDrugs"
      :key="continueDrug.id"
      :product="continueDrug"
      @click="goToProduct(continueDrug)"
    />
    <div v-if="continueDrugs?.length === 0">
      <p class="no-item-text">
        {{ t('myDiaryDrugs.noItem') }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import MicroProductCard from './MicroProductCard'

export default {
  components: {
    MicroProductCard,
  },
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const { t } = useI18n()
    
    /** computed */
    const continueDrugs = computed(() => store.getters['user/getMyContinueDrugs'] ? store.getters['user/getMyContinueDrugs'].slice(0, 3) : [])

    /** method */
    const goToProduct = (product) => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)
    const goToContinueDrugs = () => router.push(`/my-drugs/continue-drugs`)
    
    return {
      t,
      continueDrugs,
      goToProduct,
      goToContinueDrugs,
    }
  }
}
</script>

<style lang="scss" scoped>

.continue-drugs-wrapper {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  padding: 16px;
}

.top-wrapper {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #F2F2F2;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}

.see-all {
  color: var(--secondary-color);
  cursor: pointer;
}
.no-item-text {
  font-size: 16px;
  margin: 12px 0;
  text-align: center;
}
</style>
