const SET_LANGUAGE = 'SET_LANGUAGE'

export default {
  namespaced: true,
  state: {
      data: {
        currentLanguage: process.env.VUE_APP_I18N_LOCALE,
      },
  },
  getters: {
    currentLanguage: state => state.currentLanguage,
  },
  actions: {
    setLanguage({ commit }, data) {
      commit(SET_LANGUAGE, data)
    }
  },
  mutations: {
    SET_LANGUAGE(state, data) {
        state.currentLanguage = data
    },
  },
}
