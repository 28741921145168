<template>
  <div class="filter-modal-wrapper" :class="{'active': modelValue}">
    <div class="filter-modal-card">
      <div class="top-wrapper">
        {{ t('filter.advanced') }}
      </div>
      <div class="filter-item-wrapper">
        <p class="title">
          {{ t('filter.category') }}
        </p>
        <div class="grid-2">
          <m-button
            outline
            @click="applyCategory(item.title)"
            v-for="(item, index) in categories"
            :key="index"
            :class="{'selected': selectedCategories.includes(item.title)}"
          >
            {{ item.title }}
          </m-button>
        </div>
      </div>
      <div class="filter-item-wrapper">
        <p class="title">
          {{ t('filter.priceRange') }}
        </p>
        <div class="inputs-wrapper">
          <TextInput
            type="number"
            v-model="priceFrom"
            :placeholder="t('filter.inputLowestPrice')"
          />
          <span>-</span>
          <TextInput
            type="number"
            v-model="priceTo"
            :placeholder="t('filter.inputHighestPrice')"
          />
        </div>
        <div class="buttons-wrapper">
          <m-button outline @click="applyPrice(0, 150)" class="p-4">
            0 - 150
          </m-button>
          <m-button outline @click="applyPrice(150, 450)" class="p-4">
            150 - 450
          </m-button>
          <m-button outline @click="applyPrice(450, 1000)" class="p-4">
            450 - 1000
          </m-button>
        </div>
      </div>
      <div class="bottom-button-wrapper">
        <MButton outline fullWidth @click="clear">{{ t('filter.clear') }}</MButton>
        <MButton class="mt-a " fullWidth @click="submit">{{ t('submit') }}</MButton>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

import TextInput from '@/components/Form/TextInput'
import MButton from '@/components/Form/MButton'

export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
      default: () => null
    }
  },
  components: {
    TextInput,
    MButton
  },
  setup(props, { emit }) {
    /** context */
    const store = useStore()
    const { t } = useI18n()

    /** constant */
    const items = [
      { id: 1, label: t('filter.name'), value: 'name' },
      { id: 2, label: t('filter.price'), value: 'price' },
      { id: 3, label: t('filter.discount'), value: 'discount' },
      { id: 4, label: t('filter.sold'), value: 'sold' },
      { id: 5, label: t('filter.numVisits'), value: 'num_visits' },
    ]

    /** reactive */
    const selectedCategories = computed(() => store.state.filter.data.categories)
    const priceFrom = computed({
      get: () => store.state.filter.data.priceFrom,
      set: (val) => store.commit('filter/SET_PRICE_FROM', val)
    })
    const priceTo = computed({
      get: () => store.state.filter.data.priceTo,
      set: (val) => store.commit('filter/SET_PRICE_TO', val)
    })
    const categories = computed(() => store.state.frontpage.data.categories)
    
    /** method */
    const applyCategory = (val) => {
      store.commit('filter/SET_CATEGORY', val)
    }
    const applyPrice = (from, to) => {
      store.commit('filter/SET_PRICE_FROM', from)
      store.commit('filter/SET_PRICE_TO', to)
    }
    const close = () => {
      emit('update:modelValue', !props.modelValue)
    }
    const submit = () => {
      close()
      props.callback()
    }
    const clear = () => {
      store.commit('filter/RESET_CATEGORY')
      store.commit('filter/SET_PRICE_FROM', 0)
      store.commit('filter/SET_PRICE_TO', 0)
      close()
      props.callback()
    }

    return {
      t,
      close,
      items,
      applyPrice,
      applyCategory,
      priceFrom,
      priceTo,
      submit,
      categories,
      selectedCategories,
      clear,
    }
  }
}
</script>

<style lang="scss" scoped>

.filter-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // @media screen and (min-width: 780px) {
  //   left: 50%;
  //   right: auto;
  //   transform: translateX(-50%);
  //   width: 780px;
  // }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 8;
  transform: translateX(100%);
  transition: all 0.5s ease;
  &.active {
    background-color: rgba(255, 255, 255, 0.7);
    transform: translateX(0%);
  }
}

.filter-modal-card {
  width: 80%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.top-wrapper {
  font-weight: bold;
  font-size: 24px;
  padding: 12px 8px;
  background: #eeeeee;
}

.filter-item-wrapper {
  padding: 12px 8px;
  border-bottom: 1px solid var(--grey-color);
  > .title {
    margin: 0 0 8px;
  }
  > .buttons-wrapper {
    margin-top: 12px;
    display: flex;
    gap: 12px;
    > * {
      flex: 1;
    }
  }
  > .grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
  > .inputs-wrapper {
    background-color: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;
    &.pt-0 {
      padding-top: 0;
    }
    > span {
      margin: 0 12px;
      font-size: 20px;
    }
    > div {
      flex: 1;
    }
    .input-wrapper {
      margin-bottom: 0;
    }
  }
}

.flex {
  display: flex;
  // width: 100%;
  gap: 12px;
  > * {
    // flex: 1;
  }
  > span {
    flex: 0;
    margin: auto 0;
  }
}

.selected {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.bottom-button-wrapper {
  margin-top: auto;
  display: flex;
  gap: 12px;
  padding: 12px;
}

.p-4 {
  padding: 8px 0px;
}
</style>
