<template>
  <div class="timer-wrapper">
    {{ t('remaining') }} {{ displayTimer }}
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import millisToMinutesAndSeconds from '@/utils/millisToMinutesAndSeconds'

export default {
  name: 'TimerComp',
  props: {
    seconds: {
      type: Number,
      default: 0,
    }
  },
  setup(props) {
    /** context */
    const { t } = useI18n()
    
    /** reactive */
    const timer = ref(null)
    const current = ref(props.seconds)

    /** computed */
    const displayTimer = computed(() => millisToMinutesAndSeconds(current.value))

    /** mounted */
    onMounted(() => {
      timer.value = setInterval(() => {
        current.value = current.value - 1000
      }, 1000)
    })

    onUnmounted(() => {
      if (timer.value) {
        clearInterval(timer.value)
      }
    })

    return {
      t,
      displayTimer,
    }
  }
}
</script>

<style lang="scss" scoped>

.timer-wrapper {
  text-align: center;
  font-size: 20px;
  margin-bottom: 12px;
}
</style>
