const minLength = min => {
  return input => input.length < min 
  ? `ต้องมีความยาว ${min} ขึ้นไป` 
  : null
}

const isPhoneNumber = input => {
  const re = /(?=.*\d).{10}/
  return re.test(input)
  ? null
  : 'หมายเลขโทรศัพท์มือถือของท่านไม่ถูกต้อง'
}

const isIdCardNumber = input => {
  
  if (input.length != 13) return 'หมายเลขบัตรประชาชนของท่านไม่ถูกต้อง'
  let sum = 0
  for (let i = 0; i < 12; i++) {
    sum += parseInt(input.charAt(i)) * (13 - i)
  }
  let mod = sum % 11
  let check = (11 - mod) % 10
  if (check == parseInt(input.charAt(12))) {
    return null
  }
  return 'หมายเลขบัตรประชาชนของท่านไม่ถูกต้อง'
}

const isRequired = input => input ? null : 'กรุณากรอก'


const isEmail = input => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(input)
  ? null
  : 'ที่อยู่อีเมลของคุณไม่ถูกต้อง'
}

export {
  minLength,
  isEmail,
  isRequired,
  isPhoneNumber,
  isIdCardNumber,
}