<template>
  <div class="content-wrapper" v-html="otpTemplate" />
</template>

<script>
import { computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'otpView',
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()

    const otpTemplate = computed(() => store.state.order.otpTemplate)

    onMounted(() => {
      const form = document.getElementsByTagName('form')
      if (form[0]) {
        form[0].submit()
      }
      else {
        router.back()
      }
    })
    return {
      otpTemplate,
    }
  },
}
</script>

<style lang="scss" scoped>

.content-wrapper {
  height: 100%;
  width: 100%;
}
</style>
