<template>
  <div class="continue-wrapper">
    <div class="check-box" :class="{ 'is-checked': selectedDrugs.includes(item.id) }" @click="toggleSelectProduct(item)">
      <div class="inside">
        <img src="@/assets/icons/small-check.svg" alt="check">
      </div>
    </div>
    <img
      v-if="item.thumbnail"
      class="product-img"
      :src="item.thumbnail"
      alt="product-img"
      @click="goToProduct(item)"
    />
    <div class="product-description" @click="goToProduct(item)">
      <p class="name mb-1">{{ item.name }}</p>
      <p class="description">{{ item.description }}</p>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    toggleSelectProduct: {
      type: Function,
      default: () => null
    },
    selectedDrugs: {
      type: Array,
    }
  },
  setup() {
    const router = useRouter()

    /** method */
    const goToProduct = (product) => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}&b=1`)

    return {
      goToProduct,
    }
  }
}
</script>

<style lang="scss" scoped>

.continue-wrapper {
  border-radius: 6px;
  border: 1px solid #C3C3C3;
  display: flex;
  margin-bottom: 16px;
}

.product-img {
  width: 25%;
  margin: 16px 0 16px 16px;
  border-radius: 6px;
  &:active {
    opacity: 0.7;
  }
}

.product-description {
  border-right: 1px solid #C3C3C3;
  padding: 16px;
  flex: 1;
  &:active {
    opacity: 0.7;
  }
  > .name {
    font-size: 1.5rem;
    font-weight: bold;
  }
  > .description {
    font-size: 1.25rem;
  }
}

.how-to-use-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  &:active {
    opacity: 0.7;
  }
}

.how-to-text {
  font-size: 1.25rem;
  color: #4A58E5;
}


.check-box {
  margin-left: 12px;
  margin-top: 12px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  overflow: hidden;
  &.all {
    position: static;
    // top: inherit;
    // right: inherit;
  }
  .inside {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(0);
    background-color: var(--primary-color);
    transition: all 0.2s ease;
    > img {
    }
  }
  &.is-checked {
    > .inside {
      transform: scale(1);
    }
  }
}
</style>
