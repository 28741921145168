<template>
  <div class="wrapper" v-if="product">
    <ProductNavbar />
    <ProductBottomBar :product="product" />
    <ImageSlider :items="productImages" :autoplay="false" :wrap-around="false" />
    <div class="detail-wrapper">
      <div class="top-wrapper">
        <PriceTag :price="numberWithCommas(product.price)" :discount="numberWithCommas(product.discount)" />
        <div class="actions-wrapper">
          <ActionButton name='like' @click="toggleLike" :isActive="product.is_favorite" />
          <ActionButton name='share' @click="share" />
        </div>
      </div>
      <p class="title">{{ product.name[locale] }}</p>
      <p class="sold-amount">{{ t('soldAmount', [ product.soldAmount ]) }}</p>
      <BrandTag
        v-if="product.band"
        class="brand-wrapper"
        :url="product.brand.image"
        :title="product.brand.title"
      />
      <!-- <FlashDeal class="flash-deal-wrapper" /> -->
      <StoreTag
        v-if="product.seller"
        :url="product.seller.image"
        :title="product.seller.title"
      />
      <CategoryMenu>
        <div v-html="product.content" />
      </CategoryMenu>
      <div class="tags-wrapper">
        <CategoryTag v-for="tag in product.tags" :key="tag">{{ tag }}</CategoryTag>
      </div>
      <p class="relate-product-header">Relate with this product</p>
      <div class="products-wrapper">
        <ProductCard
          v-for="(recommendProduct, index) in product.recommendProducts"
          :key="index"
          :title="recommendProduct.name"
          :price="numberWithCommas(recommendProduct.price)"
          :sold="numberWithCommas(recommendProduct.sold)"
          :thumbnail="recommendProduct.thumbnail"
          @click="goToProduct(recommendProduct)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useHead } from '@unhead/vue'
import numberWithCommas from '@/utils/numberWithCommas'

import BrandTag from '@/components/BrandTag'
import PriceTag from '@/components/PriceTag'
import StoreTag from '@/components/StoreTag'
// import FlashDeal from '@/components/FlashDeal'
import CategoryTag from '@/components/CategoryTag'
import ImageSlider from '@/components/ImageSlider'
import ProductCard from '@/components/ProductCard'
import ActionButton from '@/components/ActionButton'
import CategoryMenu from '@/components/CategoryMenu'
import ProductNavbar from '@/components/ProductNavbar'
import ProductBottomBar from '@/components/ProductBottomBar'

export default {
  name: 'ProductById',
  components: {
    BrandTag,
    PriceTag,
    StoreTag,
    // FlashDeal,
    CategoryTag,
    ImageSlider,
    ProductCard,
    ActionButton,
    CategoryMenu,
    ProductNavbar,
    ProductBottomBar,
  },
  setup() {
    /** context */
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t, locale } = useI18n()

    const queryProduct = {
      name: route.query.name,
      description: route.query.desc,
      price: route.query.price,
      image: route.query.image,
    }
    
    /** constant */
    const id = ref(route.params.id)
    
    /** computed */
    const product = computed(() => store.getters['products/getProductById'](id.value))
    const productImages = computed(() => product.value?.images?.map(image => ({ img_url: image })))
    /** method */
    const toggleLike = () => {
      const is_favorite = product.value.is_favorite
      store.dispatch('products/fetchUpdateFavoriteProductById', { id: id.value, is_favorite: !is_favorite })
    }
    const goToProduct = (product) => router.push(`/product/${product.id}?name=${product.name}&desc=${product.description}&price=${product.price}&image=${product.thumbnail}`)
    const addProductToCart = (product) => store.commit('cart/ADD_PRODUCT', { ...product, amount: 1 })
    const share = () => {
      try {
        navigator.share({
          title: product.value.name[locale],
          url: window.location.href
        })
      }
      catch (err) {
        navigator.clipboard.writeText(window.location.href)
        store.commit('snackBar/SET_DATA', { message: t('copyToClipboard'), color: 'success' })
      }
    }
    
    /** mounted */
    onMounted(() => {
      store.dispatch('products/fetchProductById', id.value)
    })
    
    watch(
      () => route.params.id,
      () => {
        store.dispatch('products/fetchProductById', route.params.id)
        id.value = route.params.id
      }
    )

    useHead({
      title: () => `${queryProduct.name} - Makalin Connect`,
      meta: [
        {
          name: 'description',
          content: () => `${queryProduct.description !== 'null' ? queryProduct.description : ''} ${queryProduct.price} ${t('baht')}`
        },
        {
          property: 'og:image',
          content: () => queryProduct.image
        }
      ]
    })

    return {
      t,
      locale,
      product,
      productImages,
      toggleLike,
      goToProduct,
      addProductToCart,
      share,
      numberWithCommas
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-wrapper {
  padding: 16px;
  padding-bottom: 60px;
}

.top-wrapper {
  display: flex;
  justify-content: space-between;
}

.actions-wrapper {
  button {
    margin-left: 18px;
  }
}

.title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 8px 0 0;
}

.sold-amount {
  font-size: 12px;
  font-weight: bold;
  margin: 8px 0 0;
  color: #6A6A6A;
}

.brand-wrapper {
  margin: 24px 0;
}

.flash-deal-wrapper {
  margin-bottom: 24px;
}

.relate-product-header {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 16px 0;
}

.products-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-right: -8px;
  padding-top: 16px;
  border-top: 1px solid #C4C4C4;
  margin-top: 16px;
  > div {
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
</style>
