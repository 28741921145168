<template>
  <button>
    <img :src="require(`@/assets/icons/${name}${isActive ? '-active' : ''}.svg`)" :alt="name" />
  </button>
</template>

<script>

export default {
  props: {
    name: {
      require: true,
      type: String,
    },
    isActive: {
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  border: 0;
  outline: none;
  background-color: transparent;
  transition: 0.1s;
  &:active {
    transform: scale(0.8);
  }
}
</style>
