<template>
  <div class="my-addresses-container">
    <div class="top-header-wrapper">
      <button @click="back">
        <img src="@/assets/icons/primary-back.svg" alt="back">
      </button>
      <p class="title">{{ t('addAddresses.editTitle') }}</p>
    </div>
    <div class="content-wrapper">
      <!-- <div class="group-button-wrapper">
        <ButtonSelector :label="t('addAddresses.inCountry')" :value="true" v-model="inCountry" />
        <ButtonSelector :label="t('addAddresses.abroad')" :value="false" v-model="inCountry"/>
      </div> -->
     <div v-if="inCountry" class="form-wrapper">
      <TextInput v-model="receiveName" :label="t('addAddresses.form.receiveName')" required />
      <TextInput v-model="phone" :label="t('addAddresses.form.phone')" required />
      <DropdownInput
        :label="t('addAddresses.form.address')"
        :items="addresses"
        v-model="address"
        required
      />
      <TextInput v-model="addressNo" :label="t('addAddresses.form.addressNo')" required />
      <DropdownInput
        :label="t('addAddresses.form.province')"
        :items="provinceChoices"
        v-model="province"
        required
      />
      <DropdownInput
        :label="t('addAddresses.form.district')"
        :items="districtChoices"
        v-model="district"
        required
      />
      <DropdownInput
        :label="t('addAddresses.form.subDistrict')"
        :items="subDistrictChoices"
        v-model="subDistrict"
        required
      />
      <TextInput v-model="zipCode" :label="t('addAddresses.form.zipCode')" required />
      <CheckBox v-model="isDefault" :label="t('addAddresses.form.setMainAddress')" class="mb-2" />
      <MButton fullWidth @click="add">{{ t('addAddresses.form.save') }}</MButton>
      <!-- <MButton fullWidth ghost class="mt-2">{{ t('addAddresses.form.skip') }}</MButton> -->
     </div>
     <!-- <div v-else-if="!inCountry" class="form-wrapper">
       <TextInput v-model="receiveName" :label="t('addAddresses.form.receiveName')" required />
       <TextInput v-model="phone" :label="t('addAddresses.form.phone')" required />
       <TextInput v-model="address" :label="t('addAddresses.form.address')" required />
       <TextInput v-model="addressNo" :label="t('addAddresses.form.addressNo')" required />
       <TextInput v-model="province" :label="t('addAddresses.form.province')" required />
       <TextInput v-model="district" :label="t('addAddresses.form.district')" required />
       <TextInput v-model="subDistrict" :label="t('addAddresses.form.subDistrict')" required />
       <TextInput v-model="zipCode" :label="t('addAddresses.form.zipCode')" required />
       <CheckBox v-model="isDefault" :label="t('addAddresses.form.setMainAddress')" class="mb-2" />
       <MButton fullWidth @click="add">{{ t('addAddresses.form.save') }}</MButton>
       <MButton fullWidth ghost class="mt-2">{{ t('addAddresses.form.skip') }}</MButton>
     </div> -->
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect, computed, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import TextInput from '@/components/Form/TextInput'
import MButton from '@/components/Form/MButton'
import DropdownInput from '@/components/Form/DropdownInput'
import CheckBox from '@/components/Form/CheckBox'

export default {
  name: 'MyAddressView',
  components: {
    TextInput,
    MButton,
    DropdownInput,
    CheckBox,
},
  setup() {
    /** context */
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { t } = useI18n()
    const id = route.params.id

    /** reactive */
    const inCountry = ref(true) 
    const receiveName = ref('')
    const phone = ref('')
    const address = ref({ value: t('addAddresses.form.home'), label:  t('addAddresses.form.home') })
    const addressNo = ref('')
    const province = ref({})
    const district = ref({})
    const subDistrict = ref({})
    const zipCode = ref('')
    const isDefault = ref(false)
    const isInitialed = ref(false)
    const addresses = [
      { value: t('addAddresses.form.home'), label:  t('addAddresses.form.home') },
      { value: t('addAddresses.form.work'), label:  t('addAddresses.form.work') },
    ]

    /** computed */
    const currentAddress = computed(() => store.state.user.address)
    const provinceChoices = computed(() => store.state.user.provinces.map(i => ({ ...i, value: i.province_code, label: i.province_name })))
    const districtChoices = computed(() => store.state.user.districts.map(i => ({ ...i, value: i.district_code, label: i.district_name })))
    const subDistrictChoices = computed(() => store.state.user.subDistricts.map(i => ({ ...i, value: i.sub_district_code, label: i.sub_district_name })))

    /** method */
    const back = () => router.back()
    const add = () => {
      const body = {
        name: receiveName.value,
        phone: phone.value,
        address_name: address.value?.label,
        address_number: addressNo.value,
        address_province: province.value?.label,
        address_district: district.value?.label,
        address_sub_district: subDistrict.value?.label,
        address_postcode: zipCode.value,
        is_primary: isDefault.value,
        is_in_country: inCountry.value,
      }
      store.dispatch('user/fetchUpdateAddress', { id, body })
    }

    /** mounted */
    onMounted(() => {
      store.dispatch('user/fetchGetAddress', id)
      store.dispatch('user/fetchGetProvince')
    })
    
    /** watch */
    watchEffect(() => {
      if (currentAddress.value) {
        inCountry.value = currentAddress.value.is_in_country || false
        receiveName.value = currentAddress.value.name
        phone.value = currentAddress.value.phone
        address.value = currentAddress.value.address_name ? addresses.find(a => a.label === currentAddress.value.address_name) : address.value
        addressNo.value = currentAddress.value.address_number
        zipCode.value = currentAddress.value.address_postcode
        isDefault.value = currentAddress.value.is_primary
      }
    })

    watch(() => [provinceChoices.value, currentAddress.value], ([newProvinceChoices, newCurrentAddress]) => {
      if (newProvinceChoices.length > 0 && newCurrentAddress && !isInitialed.value) {
        province.value = newProvinceChoices.find(p => p.label === newCurrentAddress.address_province)
      }
    })

    watch(() => province.value, (newValue) => {
      if (newValue?.value) {
        store.dispatch('user/fetchGetDistrict', newValue.value)
        district.value = {}
        subDistrict.value = {}
        zipCode.value = {}
      }
    })

    watch(() => [districtChoices.value, currentAddress.value], ([newDistrictChoices, newCurrentAddress]) => {
      if (newDistrictChoices.length > 0 && !district.value?.value && !isInitialed.value) {
        district.value = newDistrictChoices.find(p => p.label === newCurrentAddress.address_district)
      }
    })

    watch(() => district.value, (newValue) => {
      if (newValue?.value) {
        store.dispatch('user/fetchGetSubDistrict', newValue.value)
        subDistrict.value = {}
        zipCode.value = {}
      }
    })

    watch(() => [subDistrictChoices.value, currentAddress.value], ([newSubDistrictChoices, newCurrentAddress]) => {
      if (newSubDistrictChoices.length > 0 && !subDistrict.value?.value && !isInitialed.value) {
        subDistrict.value = newSubDistrictChoices.find(p => p.label === newCurrentAddress.address_sub_district)
        isInitialed.value = true
      }
    })

    watch(() => subDistrict.value, (newValue) => {
      if (newValue) {
        zipCode.value = newValue.postcode
      }
    })

    onUnmounted(() => store.commit('user/RESET_ADDRESS'))

    return {
      t,
      add,
      back,
      inCountry,
      receiveName,
      phone,
      address,
      addressNo,
      province,
      district,
      addresses,
      subDistrict,
      zipCode,
      isDefault,
      provinceChoices,
      districtChoices,
      subDistrictChoices,
    }
  }
}
</script>

<style lang="scss" scoped>

.my-addresses-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding-top: 56px;
}

.top-header-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #D6E2EF;
  background-color: var(--white-color);
  > button {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 20px;
    color: var(--secondary-color);
    outline: none;
    background-color: transparent;
    border: 0;
    padding: 8px;
  }
}

.group-button-wrapper {
  display: flex;
  gap: 24px;
  > * {
    flex: 1;
  }
  margin-bottom: 24px;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.content-wrapper {
  padding: 16px;
}
</style>
