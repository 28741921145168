import orderApi from '@/api/order'
import gbPrimePayApi from '@/api/gbPrimePay'
import router from '@/router'
import i18n from '@/i18n'

export default {
  namespaced: true,
  state: {
    data: [],
    currentOrder: {},
    cancelReasonList: [],
    otpTemplate: null,
  },
  getters: {},
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
    SET_CURRENT_ORDER(state, data) {
      state.currentOrder = data
    },
    SET_ORDER_STATUS(state, data) {
      state.currentOrder.status = data.order_status
    },
    SET_QR_CODE(state, data) {
      state.qrCode = data
    },
    RESET_QR_CODE(state) {
      state.qrCode = null
    },
    RESET_PAYMENT_DETAIL(state) {
      state.payment = null
    },
    SET_PAYMENT_DETAIL(state, data) {
      state.payment = data
    },
    SET_CANCEL_REASON_LIST(state, data) {
      state.cancelReasonList = data
    },
    SET_OTP_TEMPLATE(state, data) {
      state.otpTemplate = data
    },
  },
  actions: {
    async fetchGetOrders({ commit }) {
      const response = await orderApi.getOrders()
      if (response.length > 0) {
        commit('SET_DATA', response)
      }
    },
    async fetchCancelOrder({ commit }, { id, reason }) {
      try {
        await orderApi.cancelOrder(id, reason)
        commit('snackBar/SET_DATA', { message: i18n.global.t('order.successfully'), color: 'success' }, { root: true })
        router.replace(`/my-orders/${id}`)
      }
      catch (err) {
        commit('snackBar/SET_DATA', { message: i18n.global.t('order.error'), color: 'warning' }, { root: true })
      }
    },
    async fetchGetCancelReasonList({ commit }) {
      const response = await orderApi.getCancelReasonList()
      commit('SET_CANCEL_REASON_LIST', response)
    },
    async fetchGetOrderStatus({ commit }, id) {
      const response = await orderApi.getOrderStatus(id)
      commit('SET_ORDER_STATUS', response)
    },
    async fetchGetOrderById({ commit }, id) {
      const response = await orderApi.getOrderById(id)
      commit('SET_CURRENT_ORDER', response)
    },
    async fetchSaveLog({ commit }, data) {
      const response = await orderApi.saveLog(data)
      commit('SET_SAVE_LOG', response)
    },
    async fetGetPaymentDetailById({ commit }, id) {
      const response = await orderApi.getPaymentDetailById(id)
      commit('SET_PAYMENT_DETAIL', response)
      if (response.qr_code) {
        commit('SET_QR_CODE', `data:image/png;base64,${response.qr_code}`)
      }
      if (response.redirect && response.redirect_url) {
        router.push(`/redirect?redirectUrl=${response.redirect_url}&redirectMessage=${response.redirect_message}&id=${id}`)
      }
      if (response.gbp_reference_number) {
        const response3dSecured = await gbPrimePayApi.post3dSecured(response.gbp_reference_number)
        commit('SET_OTP_TEMPLATE', response3dSecured)
        router.push(`/credit-card/otp`)
      }
    },
  }
}
