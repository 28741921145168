<template>
  <div class="input-wrapper">
    <label v-if="label" for="label">{{ label }}<span v-if="required">*</span></label>
    <input
      :class="{ 'is-focus' : isFocus, 'is-disabled' : disabled, 'is-error' : errors && errors?.length > 0 }"
      :name="name"
      :type="type"
      @blur="isFocus = false"
      @focus="isFocus = true"
      @keypress="isLetterOrNumber"
      :value="modelValue"
      :disabled="disabled"
      :autofocus="autofocus"
      v-model="input"
      :placeholder="placeholder"
      v-maska
      :data-maska="mask"
    >
    <ul class="errors-message" v-if="errors && errors.length > 0">
      <li v-for="(err, index) in errors" :key="index">
        {{ err }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref } from 'vue'
import useInputValidator from '@/composables/useInputValidator'
import { isRequired } from '@/utils/validators'

export default {
  props: {
    type: {
      default: 'text',
    },
    modelValue: {
      require: true,
    },
    label:{
      default: ''
    },
    mask:{
      default: ''
    },
    isNumber: {
      default: false
    },
    placeholder:{
      default: ''
    },
    name: {
      require: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false,
    },
    validators: {
      type: Array,
      default: () => []
    },
  },
  setup(props, { emit }) {
    /** reactive */
    const isFocus = ref(false)
    const show = ref(false)

    /** composables */
    const { input, errors } = useInputValidator(
      props.modelValue, 
      [
        props.required && isRequired,
        // props.type === 'email' && isEmail,
        ...props.validators,
      ], 
      value => emit('update:modelValue', value)
    )

    const isLetterOrNumber = (evt) => {
      if (props.isNumber) {
        evt = (evt) ? evt : window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      }
      else {
        return true
      }
    }

    return {
      input,
      errors,
      isFocus,
      show,
      isLetterOrNumber,
    }
  }
}
</script>

<style lang="scss" scoped>

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
}

label {
  color: #6A6A6A;
  margin-bottom: 12px;
  > span {
    margin-left: 8px;
    color: var(--error-color);
  }
}

input {
  border: 1px solid #DFDFDF;
  padding: 8px 12px;
  border-radius: 8px;
  outline: none;
  transition: all 0.3s ease;
  width: 100%;
  &.is-focus {
    border: 1px solid var(--secondary-color);
  }
  &.is-disabled {
    background-color: #f1f1f1;
  }
  &.is-error {
    border: 1px solid var(--error-color);
  }
}

.errors-message {
  color: var(--error-color);
  font-size: 0.75rem;
  padding-left: 24px;
}
</style>
