<template>
  <div
    class="coupon-card-wrapper"
    :class="{ 'is-selected': isSelected }"
    @click="applyCoupon"
  >
    <img :src="coupon.picture_thumbnail" alt="coupon-image">
    <div class="content-wrapper">
      <p class="header">
        {{ t('myCoupon.card.discount') }} {{ coupon.discount_amount }}{{ coupon.discount_type === 'amount' ? '฿' : '%' }}
      </p>
      <p class="min">
        {{ coupon.min_total > 0 ? `${t('myCoupon.card.min')} ${coupon.min_total}฿` : `${t('myCoupon.card.noMin')}` }}
      </p>
      <p class="expired" v-if="displayExpired">
        {{ t('myCoupon.card.expired') }} {{ displayExpired }}
      </p>
      <p class="expired" v-else>
        {{ t('myCoupon.card.noExpired') }}
      </p>
    </div>
    <div
      class="check-wrapper"
      v-if="!noSelection"
      :class="{ 'is-selected': isSelected }"
    >
      <img src="@/assets/icons/small-check.svg">
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

export default {
  props: {
    coupon: {
      type: Object,
      default: null,
    },
    noSelection: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    /** context */
    const store = useStore()
    const { t } = useI18n()

    /** computed */
    const displayExpired = computed(() => props.coupon.expired ? dayjs(props.coupon.expired).format('DD/MM/YYYY') : null)
    const isSelected = computed(() => store.state.cart.data.coupon_code === props.coupon.code)

    /** method */
    const applyCoupon = () => {
      store.dispatch('cart/fetchApplyCoupon', props.coupon.code)
    }

    return {
      t,
      displayExpired,
      isSelected,
      applyCoupon,
    }
  }
}
</script>

<style lang="scss" scoped>

.coupon-card-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #C3C3C3;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  &.is-selected {
    border: 1px solid var(--success-color);
  }
  > img {
    width: 110px;
    max-height: 66px;
    object-fit: contain;
  }
  .content-wrapper {
    padding: 8px
  }
  
  .header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .min {
    color: #878787;
    font-size: 14px;
  }
  .expired {
    color: #878787;
    font-size: 14px;
  }
}

.check-wrapper {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid var(--success-color);
  margin-left: auto;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  &.is-selected {
    background-color: var(--success-color);
  }
}
</style>
